<template lang="">
  <div class="landing-mobile-menu">
    <nav class="landing-mobile-menu__nav">
      <a
        v-for="nav in listNav"
        :key="nav.path"
        :href="nav.path"
        class="landing-mobile-menu__nav-item"
        :target="nav.path === 'https://t.me/wbleads_cases' ? '_blank' : ''"
        >{{ nav.name }}</a
      >
    </nav>
    <a class="wb-btn landing-mobile-menu__registration" href="/auth/register">
      <img src="/img/landing-registration-icon.svg" alt="" loading="lazy" />
      <span>Регистрация</span>
    </a>
  </div>
</template>
<script>
export default {
  props: ['listNav'],
};
</script>
<style lang="scss">
.landing-mobile-menu {
  position: fixed;
  height: calc(100vh - 80px);
  width: 100vw;
  z-index: 1000;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  top: 80px;
  left: 0;
  padding: 40px 5%;
  &__nav > &__nav-item {
    display: block;
    font-size: 24px;
    color: #160027;
    font-weight: 400;
    text-decoration: none;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  & > &__registration {
    display: flex;
    align-items: center;
    height: 61px;
    width: 100%;
    padding: 1px 3.7rem;
    text-decoration: none;
    background: #481173;
    border-radius: 1000px;
    img {
      margin-right: 10px;
    }
  }
}
</style>
