<script setup>
import { ref, onMounted } from 'vue';

import LandingHeader from '@/components/landing/LandingHeader.vue';
import LandingStatistics from '@/components/landing/LandingStatistics.vue';
import LandingAdvantages from '@/components/landing/LandingAdvantages.vue';
import LandingFooter from '@/components/landing/LandingFooter.vue';
import LandingVideo from '@/components/landing/LandingVideo.vue';
import LandingBlockUseful from '@/components/landing/LandingBlockUseful.vue';
import LandingBanner from '@/components/landing/LandingBanner.vue';
import LandingNeed from '@/components/landing/LandingNeed.vue';
import LandingQuestions from '@/components/landing/LandingQuestions.vue';
import LandingOpportunites from '@/components/landing/LandingOpportunites.vue';
import LandingStatisticPluses from '@/components/landing/LandingStatisticPluses.vue';
import LandingTariffs from '@/components/landing/LandingTariffs.vue';
import { mobileSize } from '@/constants/mobile-size';

const isShow = ref(false);
const isMobile = window.innerWidth < mobileSize;

onMounted(() => {
  setTimeout(() => {
    isShow.value = true;
  }, 100);
});
</script>

<template>
  <div class="view_landing" :class="{ active: isShow }">
    <LandingHeader />

    <div class="view_landing__container view_landing__before_header">
      <LandingStatistics />
      <LandingStatisticPluses />
      <LandingNeed />
      <LandingBlockUseful />
    </div>

    <LandingOpportunites />

    <div class="view_landing__container">
      <LandingVideo />
      <LandingAdvantages />
      <LandingBanner />
      <LandingTariffs />
      <LandingQuestions />
    </div>

    <LandingFooter />
  </div>
</template>

<style lang="scss">
.view_landing {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 1;
  background: #fff;
  box-sizing: border-box;

  &.active {
    opacity: 1;
    transition: all 2s ease;
  }

  &__container {
    @media screen and (min-width: 570px) {
      padding: 0 100px;

      & > div {
        padding: 100px 0;
      }
    }
    padding: 0 5%;
  }

  &__statistics {
    margin-top: 60px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}

.landing_word_color {
  color: #771cbd;
}

.wb-footer-hr {
  display: none;
}

.view_landing__before_header {
  margin-top: 120px;
  @media screen and (min-width: 570px) {
    margin-top: 160px;
  }
}
</style>
