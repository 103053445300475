import axios from 'axios';

class StatisticAdvertising {
  get(params) {
    return axios({
      url: '/new-reports/dyn_advert/',
      method: 'GET',
      params: params,
    });
  }
}

export default new StatisticAdvertising();
