import { mapObjectToFixed } from '@/helpers/mapObjectToFIxed';
import moment from 'moment';

const getCurrentStatus = (status) => {
  if (status.toLowerCase() === 'активна') {
    return 'success';
  }
  if (status.toLowerCase() === 'приостановлена') {
    return 'warning';
  }
  return 'stopped';
};

export const mapItems = (items) => {
  return items.map((item) => {
    return {
      ...mapObjectToFixed(item),
      create_date: moment(item.create_date).format('DD.MM.YYYY'),
      finish_date: moment(item.create_date).format('DD.MM.YYYY'),
      photos: item.products.map((product) => product.image),
      category_name:
        item.category_name === 'Автоматическая кампания'
          ? 'АРК'
          : item.category_name,
      status: getCurrentStatus(item.status),
    };
  });
};

export const mapTotals = (totals) => {
  return mapObjectToFixed(totals);
};
