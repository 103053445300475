<script setup>
import { defineProps, ref } from 'vue';
import ImgPreview from '@/components/images/ImgPreview.vue';

const props = defineProps({
  product: Object,
});

const product = props.product.product;

const activeImg = ref(null);

const onMouseOverImg = (product) => {
  activeImg.value = product.image;
};

const onMouseLeaveImg = () => {
  activeImg.value = null;
};

const showInfo = () => {
  console.log('this', product);
};
</script>

<template>
  <div class="product">
    <img
      :src="product.image"
      alt="Product"
      class="product__img"
      @mouseover="onMouseOverImg(product)"
      @mouseleave="onMouseLeaveImg()"
      v-if="product.image"
    />

    <span class="product__img_alt" v-if="!product.image"></span>
    <a
      class="product__link"
      :href="`/reports/products-statistic/${product.nm_id}`"
    >
      {{ product.name }} {{ product.nm_id }}
    </a>

    <ImgPreview
      :src="product.image"
      :alt="'Brand'"
      :title="product.image"
      v-if="activeImg"
    />
  </div>
</template>

<style lang="scss" scoped>
.product {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: .5rem;

  &__img_alt,
  &__img {
    width: 27px;
    height: 37px;
    object-fit: cover;
    border-radius: 6px;
    background: #ccc;
  }

  &__img_alt {
    display: inline-block;
  }

  &__link {
    color: #481173;
    font-size: 12px;
    text-decoration: underline;
    font-weight: 600;
  }

  & a {
    &:hover, &:active {
      text-decoration: none;
    }
  }
}
</style>
