<template>
  <div class="mt-3">
    <div class="page-toolbar d-flex">
      <div class="page-title d-flex align-items-center flex-grow-1">
        Организации
      </div>

      <div>
        <span v-if="!loading">
          <b-btn
            @click="addUser"
            class="wb-btn"
            :disabled="
              !currentUserTariffInfo.has_active_subscription ||
              isDisabledAction()
            "
          >
            <b-icon-plus />
            Добавить организацию
          </b-btn>
        </span>
      </div>
    </div>

    <div class="wb-content">
      <div class="table-responsive wrap_organization-table">
        <table class="wb-table table organization-table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Имя</th>
              <th scope="col">Дата добавления</th>
              <th scope="col">Активность</th>
              <th class="wb-table-actions" scope="col">Действия</th>
            </tr>
          </thead>

          <tbody>
            <template v-if="!loading">
              <tr v-for="item in users" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.fullname }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                  <div>
                    <b-form-checkbox
                      v-model="item.is_active"
                      @change="handleChangeActive(item)"
                      :options="options"
                      switch
                      :disabled="!currentUserTariffInfo.has_active_subscription"
                    >
                    </b-form-checkbox>

                    <router-link
                      to="/profile"
                      class="subscription-noti"
                      v-if="!currentUserTariffInfo.has_active_subscription"
                    >
                      Активируйте подписку
                    </router-link>
                  </div>
                  <div v-if="!item.tokens_state.wb_api_token">
                    <span class="invalid-token">Неверный WBToken</span>
                  </div>
                </td>
                <td class="wb-table-actions-body" style="list-style: none">
                  <div class="container_btns">
                    <button
                      class="btn_icon"
                      @click="showModalOrganization(item)"
                    >
                      <img src="/img/icon-edit.svg" />
                    </button>
                    <b-dropdown-item-button
                      v-b-modal.delete
                      variant="danger"
                      @click="deleteUser(item)"
                    >
                      <img src="../../assets/TrashSimple.svg" alt="" />
                    </b-dropdown-item-button>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="5" class="text-center">Загрузка...</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal
      id="add"
      v-if="stage === 1"
      @hide="modalCloseHandler"
      centered
      :title="
        organization && organization.id
          ? 'Редактирование организации'
          : 'Подключение организации'
      "
    >
      <WBLoader v-show="isShowLoadingModal" type="modal" />

      <div class="org-modal-grid">
        <div>
          <router-link
            to="/instruction"
            target="_blank"
            class="instruction-link"
          >
            Инструкция
          </router-link>

          <div class="mt-4 form_user">
            <b-form-input
              class="mt-3"
              placeholder="Название организации"
              v-model="name_organization"
              :state="getInputState('fullname')"
            />
            <b-form-invalid-feedback
              id="name_organization"
              v-if="organizationErrorOptions.indexOf('fullname') > -1"
            >
              {{ errorName('fullname') }}
            </b-form-invalid-feedback>

            <b-form-input
              class="mt-3"
              placeholder="API Token"
              v-model="wb_api_token"
              :state="getInputState('wb_api_token')"
            />
            <b-form-invalid-feedback
              id="wb_api_token"
              v-if="organizationErrorOptions.indexOf('wb_api_token') > -1"
            >
              {{ errorName('wb_api_token') }}
            </b-form-invalid-feedback>
          </div>

          <div style="width: 100%; margin-top: 30px">
            <div>
              <b-btn class="mt-4 w-100 py-2" variant="wblead" @click="save">
                {{ organization && organization.id ? 'Сохранить' : 'Добавить' }}
              </b-btn>
              <p
                class="text-center"
                style="color: #908f90; margin-top: 8px; font-size: 14px"
              >
                После добавления организации, статистика <br />
                подтягивается в течение 2 - 4 часов
              </p>
            </div>
          </div>
        </div>

        <div>
          <img src="/img/screen-org-new.svg" class="img-screen" alt="" />
        </div>
      </div>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal @hide="modalCloseHandler" v-if="stage === 2" centered>
      <div class="modal-subheader">
        <img style="width: 100%" :src="captcha_image" alt="" />
      </div>

      <div class="mt-4">
        <b-form-input
          v-model="captcha_code"
          placeholder="Введите код с картинки"
        />
      </div>

      <template #modal-footer>
        <b-btn class="mt-4 w-100 py-2" variant="wblead" @click="send"
          >Продолжить
        </b-btn>
      </template>
    </b-modal>

    <b-modal v-if="stage === 3" centered title="Подключение организации">
      <div class="modal-subheader">
        Введите код, отправленный на ваш номер телефона
      </div>

      <div class="mt-4">
        <div
          id="otp"
          class="inputs d-flex flex-row justify-content-center mb-3"
        >
          <input
            class="m-2 text-center form-control rounded"
            @keyup="inputenter(1)"
            type="text"
            id="input1"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            @keyup="inputenter(2)"
            type="text"
            id="input2"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            @keyup="inputenter(3)"
            type="text"
            id="input3"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            @keyup="inputenter(4)"
            type="text"
            id="input4"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            @keyup="inputenter(5)"
            type="text"
            id="input5"
            maxlength="1"
          />
          <input
            class="m-2 text-center form-control rounded"
            @keyup="inputenter(6)"
            type="text"
            id="input6"
            maxlength="1"
          />
        </div>

        <!--        <div v-if="countdown !== 0" class="resend">Переотправить код через {{ new Date(countdown * 1000).toISOString().substring(14, 19) }}</div>-->
        <!--        <div v-else @click="resend" class="resend" style="cursor:pointer;">Отправить код заново</div>-->
      </div>

      <template #modal-footer>
        <b-btn class="mt-4 w-100 py-2" variant="wblead" @click="send"
          >Подтвердить
        </b-btn>
      </template>
    </b-modal>
    <b-modal
      @hide="modalCloseHandler"
      v-if="stage === 4"
      centered
      title="Подключение организации"
    >
      <div class="modal-subheader">
        Выберите организации, которые хотите добавить
      </div>
      <div v-for="(user, index) of supliers" class="mt-4" :key="index">
        <div class="mt-2">
          <b-form-checkbox
            :id="`checkbox-${user.id}`"
            :name="`checkbox-${user.id}`"
            v-model="user.is_active"
            @change="activeHandler(user)"
            variant="wblead"
            unchecked-value="false"
          >
            <span> {{ user.fullname }}</span>
          </b-form-checkbox>
        </div>
      </div>
      <template #modal-footer>
        <b-btn class="mt-4 w-100 py-2" variant="wblead" @click="addSupliers"
          >Добавить
        </b-btn>
      </template>
    </b-modal>

    <b-modal
      @hide="modalCloseHandler"
      v-if="stage === 6"
      centered
      title="Подключение организации"
    >
      <div class="modal-subheader">
        <div class="d-flex">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="rd-3"
              name="rd"
              class="custom-control-input"
              value="google"
              v-model="selectedTab"
            />
            <label class="custom-control-label purple" for="rd-3">
              <span class="radio-label">Google Chrome</span>
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="rd-4"
              name="rd"
              class="custom-control-input"
              value="yandex"
              v-model="selectedTab"
            />
            <label class="custom-control-label purple" for="rd-4">
              <span class="radio-label">Yandex</span></label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="rd-5"
              name="rd"
              class="custom-control-input"
              value="safari"
              v-model="selectedTab"
            />
            <label class="custom-control-label purple" for="rd-5">
              <span class="radio-label">Safari</span></label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="rd-6"
              name="rd"
              class="custom-control-input"
              value="firefox"
              v-model="selectedTab"
            />
            <label class="custom-control-label purple" for="rd-6">
              <span class="radio-label">Firefox</span></label
            >
          </div>
        </div>
      </div>

      <template #modal-footer>
        <b-btn
          class="mt-4 w-100 py-2"
          variant="wblead"
          @click="addSupliers"
          v-if="stage !== 6"
          >Добавить
        </b-btn>
        <b-btn class="mt-4 w-100 py-2" variant="wblead" @click="stage = 1">
          Закрыть
        </b-btn>
      </template>
    </b-modal>
    <b-modal id="delete">
      <h5>Вы хотите хотите удалить эту организацию?</h5>
      <template #modal-footer>
        <div class="d-flex justify-content-center m-auto">
          <b-btn
            class="mt-4 py-2 mr-2"
            style="
              background: #e9dbf3;
              border: none;
              color: #481173;
              width: 120px;
            "
            @click="$bvModal.hide('delete')"
            >Нет
          </b-btn>
          <b-btn
            class="mt-4 py-2 ml-2"
            style="width: 120px"
            variant="wblead"
            @click="deleteOrganization"
            >Да
          </b-btn>
        </div>
      </template>
    </b-modal>
    <b-alert
      v-model="alertError"
      class="position-fixed fixed-bottom m-0 rounded-0"
      style="z-index: 2000"
      variant="danger"
      dismissible
    >
      Ошибка
    </b-alert>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import axios from 'axios';
import OrganizationApi from '@/api/organization';
import WBLoader from '@/components/loader/WBLoader.vue';

export default {
  name: 'OrganizationsView',
  components: { WBLoader },

  data() {
    return {
      countdown: 0,
      perPage: 10,
      currentPage: 0,
      items: [
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },

        { id: 1, first_name: 'Fred', last_name: 'Flintstone' },
        { id: 2, first_name: 'Wilma', last_name: 'Flintstone' },
        { id: 3, first_name: 'Barney', last_name: 'Rubble' },
        { id: 4, first_name: 'Betty', last_name: 'Rubble' },
        { id: 5, first_name: 'Pebbles', last_name: 'Flintstone' },
        { id: 6, first_name: 'Bamm Bamm', last_name: 'Rubble' },
        { id: 7, first_name: 'The Great', last_name: 'Gazzoo' },
        { id: 8, first_name: 'Rockhead', last_name: 'Slate' },
        { id: 9, first_name: 'Pearl', last_name: 'Slaghoople' },
      ],
      interval: null,
      processing: false,
      selectedTab: 'google',
      name_organization: '',
      passportToken: '',
      seller_wb_token: '',
      supplier_id: '',
      phone: '',
      captcha_code: '',
      captcha_image: '',
      code: '',
      stage: 1,
      current_id: '',
      selectedOption: 'token',
      loading: false,
      alertError: false,
      organization: {},
      api_statistic: null,
      wb_api_token: null,
      advert_token: null,
      organizationErrorOptions: [],
      statistic_token: null,
      wb_token: null,
      listErrorKeyValue: [],
      isShowLoadingModal: false,
      activeTooltip: null,
      selected: [],
      options: [{ text: '', value: 'true' }],
      showErrorTooltip: false,
    };
  },

  methods: {
    ...mapActions('organizations', [
      'sendUser',
      'checkCaptcha',
      'checkSmsCode',
      'postActiveSupliers',
      'getSupliers',
      'deleteSuplier',
      'sendUserByToken',
    ]),
    ...mapActions(['getUsers', 'setUser']),

    isDisabledAction() {
      if (
        this.currentUserTariffInfo?.current_subscription?.tariff?.org_count >
        this.users.length
      )
        return false;

      return true;
    },

    setActiveTooltip(event, index = null) {
      this.activeTooltip = index;
    },

    handleChangeActive(item) {
      axios({
        url: `/wb-profile/activate/${item.id}/`,
        method: 'POST',
      }).catch((err) => {
        item.is_active = !item.is_active;
        this.$notify({
          type: 'error',
          title: `Достигнут лимит активных организаций`,
        });
      });

      //https://dev.wbmetrics.ru/docs/#/wb-profile/wb_profile_activate_create
    },

    getErrorTokenText(state) {
      if (!state.wb_api_token) return 'Истёк WB Token';

      if (!state.seller_wb_token) return 'Истёк Seller WB Token';

      return null;
    },

    getInputState(prop) {
      if (this.organizationErrorOptions.indexOf(prop) > -1) return false;

      if (!this.organizationErrorOptions[0]) return null;

      return true;
    },

    errorName(prop) {
      for (let option of this.listErrorKeyValue) {
        if (option.key === prop) return option.text;
      }

      return 'Проверьте поле';
    },

    clearListErrors() {
      this.listErrorKeyValue = [];
      this.organizationErrorOptions = [];
    },

    save() {
      this.clearListErrors();

      if (this.organization && this.organization.id) this.edit();
      else this.send();
    },

    async send() {
      try {
        if (this.stage === 1) {
          if (this.selectedOption === 'phone') {
            await this.sendUser({ phone: this.phone }).then((res) => {
              this.captcha_image =
                'https://dev.wbmetrics.ru/' + res.path_captcha_image;
              this.stage++;
              console.log('res', res);
              this.setUser(res);
            });
          } else if (this.selectedOption === 'token') {
            let data = {
              fullname: this.name_organization,
              seller_wb_token: this.seller_wb_token,
              wb_api_token: this.wb_api_token,
              x_supplier_id: this.supplier_id,
            };

            this.isShowLoadingModal = true;
            await this.sendUserByToken(data).then((res) => {
              console.log('resp', res);

              if (res.status === 200 || res.status === 201) {
                this.$bvModal.hide('add');
                this.isShowLoadingModal = false;

                setTimeout(() => {
                  this.getUsers({
                    options: {
                      isNewUser: true,
                      newUser: res,
                    },
                  });
                  return;
                }, 200);
              } else {
                if (res.data === 'Bad wb token') {
                  this.organizationErrorOptions.push('wb_token');
                  this.listErrorKeyValue.push({
                    key: 'wb_token',
                    text: 'Проверьте поле WB_Token',
                  });

                  this.isShowLoadingModal = false;
                  return;
                }

                for (let option in res.data) {
                  this.organizationErrorOptions.push(option);

                  let text = res.data[option][0];
                  if (option === 'wb_api_token') {
                    text = res.data[option].message || '';
                  }

                  this.listErrorKeyValue.push({
                    key: option,
                    text: text,
                  });
                }

                this.isShowLoadingModal = false;
                if (!res.data) {
                  this.$bvModal.hide('add');
                  setTimeout(() => {
                    this.getUsers({
                      options: {
                        isNewUser: true,
                        newUser: res,
                      },
                    });
                  }, 200);
                }
              }
            });
          }

          // this.stage = 2
        } else if (this.stage === 2) {
          await this.checkCaptcha({
            captcha_code: this.captcha_code,
            phone: this.phone,
          }).then((res) => {
            this.stage++;
          });
        } else if (this.stage === 3) {
          await this.checkSmsCode({
            sms_code: this.code,
            phone: this.phone,
          }).then((res) => {
            if (res.status === 500) {
              this.alertError = true;
            } else {
              this.fetchSupliers().then((Res) => {});
              this.stage = 4;
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchSupliers() {
      await this.getSupliers({ page: this.currentPage });
    },
    async addSupliers() {
      let active_ids = [];
      for (let i = 0; i < this.supliers.length; i++) {
        if (this.supliers[i].is_active === true) {
          active_ids.push(this.supliers[i].x_supplier_id);
        }
      }
      await this.postActiveSupliers(active_ids);
      this.state = null;
      this.$bvModal.hide('add');
    },
    goToInsctruction() {
      this.stage = 6;
    },
    modalCloseHandler() {
      this.stage = 1;
    },

    deleteUser(value) {
      this.current_id = value.id;
    },

    async deleteOrganization(organization) {
      try {
        this.loading = true;
        await this.deleteSuplier({
          current_id: this.current_id,
          accessToken: this.$store.state.auth.accessToken,
        });

        this.$bvModal.hide('delete');
        setTimeout(() => {
          this.getUsers();
          this.loading = false;
        }, 200);
      } catch (err) {
        this.$bvModal.hide('delete');
        await this.getUsers();
        this.loading = false;
      }
    },
    activeHandler(user) {
      // Вызываем мутацию в хранилище для обновления значения is_active
      this.$store.commit('organizations/updateActiveStatus', user);
    },
    async resend() {
      this.checkCaptcha();
    },

    startTimer() {
      this.clearTimer();
      this.countdown = 60;

      this.interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          this.clearTimer();
        }
      }, 1000);
    },

    clearTimer() {
      clearInterval(this.interval);
      this.interval = null;
    },

    inputenter() {
      const inputs = document.querySelectorAll('#otp > *[id]');
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].addEventListener('keydown', function (event) {
          if (event.key === 'Backspace') {
            inputs[i].value = '';
            if (i !== 0) inputs[i - 1].focus();
          } else {
            if (i === inputs.length - 1 && inputs[i].value !== '') {
              return true;
            } else if (event.keyCode > 47 && event.keyCode < 58) {
              inputs[i].value = event.key;
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            } else if (event.keyCode > 64 && event.keyCode < 91) {
              inputs[i].value = String.fromCharCode(event.keyCode);
              if (i !== inputs.length - 1) inputs[i + 1].focus();
              event.preventDefault();
            }
          }
        });
      }

      let result = '';

      for (let i = 0; i < inputs.length; i++) {
        result += inputs[i].value;
      }

      this.code = result;
    },

    async edit() {
      try {
        this.isShowLoadingModal = true;

        const organization = {
          fullname: this.name_organization,
          x_supplier_id: this.supplier_id,
          is_active: true,
          wb_api_token: this.wb_api_token,
          advert_token: this.advert_token,
          statistic_token: this.statistic_token,
          user: this.organization.user,
          seller_wb_token: this.seller_wb_token,
          wb_token: this.wb_token,
        };

        await OrganizationApi.edit(organization, this.organization.id);
        this.isShowLoadingModal = false;
        this.$bvModal.hide('add');
        this.getUsers();
      } catch (err) {
        console.error(err);

        if (err.response) {
          if (err.response.data === 'Bad wb token') {
            this.organizationErrorOptions.push('wb_token');
            this.listErrorKeyValue.push({
              key: 'wb_token',
              text: 'Проверьте поле WB_Token',
            });

            this.isShowLoadingModal = false;
            return;
          } else {
            if (err.response.data) {
              const listErrors = err.response.data || [];

              for (let option in listErrors) {
                this.organizationErrorOptions.push(option);

                let text = listErrors[option][0];
                if (option === 'wb_api_token') {
                  text = err.response.data[option].message || '';
                }

                this.listErrorKeyValue.push({
                  key: option,
                  text: text,
                });
              }
            }
          }
        } else {
          this.alertError = true;
        }

        this.isShowLoadingModal = false;
      }
    },

    addUser() {
      this.organization = {};
      this.name_organization = '';
      this.passportToken = '';
      this.supplier_id = '';
      this.wb_api_token = '';
      this.api_statistic = '';
      this.advert_token = '';
      this.statistic_token = '';
      this.seller_wb_token = '';
      this.wb_token = '';
      this.alertError = false;
      this.clearListErrors();

      this.$bvModal.show('add');
    },

    showModalOrganization(item) {
      this.organization = item;
      this.name_organization = item.fullname || '';
      this.passportToken = item.wb_token || '';
      this.wb_token = item.wb_token || '';
      this.supplier_id = item.x_supplier_id || '';
      this.wb_api_token = item.wb_api_token || '';
      this.api_statistic = item.statistic_token || '';
      this.advert_token = item.advert_token || '';
      this.seller_wb_token = item.seller_wb_token || '';
      this.statistic_token = item.statistic_token || '';

      this.clearListErrors();
      const tokenError = this.getErrorTokenText(item.tokens_state);
      if (tokenError) {
        this.organizationErrorOptions.push('seller_wb_token');
        this.listErrorKeyValue.push({
          key: 'seller_wb_token',
          text: 'Токен истёк',
        });
      }

      this.$bvModal.show('add');
    },
  },
  computed: {
    ...mapGetters(['users', 'currentUserTariffInfo']),
    supliers() {
      return this.$store.getters['organizations/supliers'];
    },
    rows() {
      return this.$store.getters['organizations/rows_length'];
    },
  },
};
</script>

<style lang="scss">
.resend {
  text-align: center;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #481173;
}

.inputs input {
  width: 48px;
  height: 56px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid #6f1667;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #7b1fa2;
}

.radio-label {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
}

.custom-control-input:checked ~ .custom-control-label.purple::before {
  background-color: #7b1fa2;
}

.info {
  cursor: pointer;
}

.form_user .form-control.is-invalid {
  border: 1px solid #f2958e !important;
  color: #f44336 !important;
  background: #fae7e6 !important;
}

.form_user .invalid-feedback {
  color: #f44336 !important;
  font-size: 14px;
}

#add {
  & .modal-body {
    position: initial;
  }
}

.token_state {
  position: relative;

  &__icon {
    color: #f44336;
    font-size: 20px;
  }

  &__text {
    margin: 0 0 0 0.5rem;
    display: inline-block;
    color: #f44336;
    font-size: 16px;
  }

  .token_state__tooltip {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    width: 300px;
    padding: 12px 1rem;
    z-index: 10;
    display: none;
    border-radius: 9px;
    box-shadow: 0 0 8px 0 rgba(47, 10, 73, 0.12);
    background: #fff;

    &.active {
      display: inline-block;
    }
  }
}

.th_token_state {
  min-width: 12ch;
}

.wrap_organization-table {
  overflow: visible !important;
}

.organization-table tbody tr:nth-last-child(-n + 5) {
  & .token_state__tooltip {
    top: auto;
    bottom: calc(100% + 10px);
  }
}

.subscription-noti {
  color: #481173;
}

.invalid-token {
  color: #f44336;
  font-size: 16px;
}

.instruction-link {
  color: #481173;
  text-decoration: none;
}

.img-screen {
  width: 341px;
  height: 340px;
  object-fit: cover;
}

.org-modal-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: calc(100% - 350px) 340px;
}

#add .modal-dialog {
  min-width: 800px !important;
}
</style>
