<script>
import { mobileSize } from '@/constants/mobile-size';
import LandingMobileMenu from './LandingMobileMenu.vue';

export default {
  components: {
    LandingMobileMenu,
  },
  data() {
    return {
      isMobile: window.innerWidth < mobileSize,
      listNav: [
        { path: '#Tariffs', name: 'Тарифы' },
        { path: '#About', name: 'О сервисе' },
        { path: '#Opportunities', name: 'Возможности' },
        { path: '#Advantages', name: 'Преимущества' },
        { path: '#Review', name: 'Обзор' },
        { path: '#Faq', name: 'FAQ' },
        { path: 'https://t.me/wbleads_cases', name: 'Отзывы' },
        { path: '/instruction', name: 'Инструкция' },
      ],
      isMobileMenuOpen: false,
    };
  },
};
</script>

<template>
  <header class="landing_header">
    <a class="landing_header__wrap_logo" href="/landing">
      <img src="/img/logo.svg" alt="Logo" class="landing_header__logo" />
    </a>

    <div class="landing_header__nav_login">
      <nav v-if="!isMobile" class="landing_header__nav">
        <a
          v-for="nav in listNav"
          :key="nav.path"
          :href="nav.path"
          class="landing_header__nav__item"
          :target="nav.path === 'https://t.me/wbleads_cases' ? '_blank' : ''"
          >{{ nav.name }}</a
        >
      </nav>

      <a class="wb-btn landing_header__login" href="/auth/login">Войти</a>
      <template v-if="isMobile">
        <input
          type="checkbox"
          id="burger-checkbox"
          class="landing_header__burger-checkbox"
          v-model="isMobileMenuOpen"
        />
        <label class="landing_header__burger" for="burger-checkbox"></label>
        <LandingMobileMenu v-if="isMobileMenuOpen" :listNav="listNav" />
      </template>
    </div>
  </header>
</template>

<style scoped lang="scss">
.landing_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #fff;
  z-index: 1000;
  padding: 14px 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 570px) {
    padding: 24px 100px;
    gap: 1rem;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nav_login {
    padding: 0;
    display: flex;
    align-items: center;
    @media screen and (min-width: 570px) {
      gap: 105px;
    }
  }

  &__wrap_logo {
    display: block;
    @media screen and (max-width: 570px) {
      img {
        width: 130px;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: flex-end;
    gap: 40px;

    &__item {
      color: #160027;
      font-size: 16px;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;

      &:hover,
      &:active {
        color: #481173;
      }
    }
  }

  &__login {
    height: 51px;
    border-radius: 100px;
    text-decoration: none;
    padding: 0 1.5rem;
    @media screen and (min-width: 570px) {
      padding: 0 2.8rem;
    }
  }
  &__burger-checkbox {
    position: absolute;
    visibility: hidden;
    &:checked + .landing_header__burger::before {
      top: 11px;
      transform: rotate(45deg);
      box-shadow: 0 6px 0 rgba(0, 0, 0, 0);
      transition: box-shadow 0.15s, top 0.3s, transform 0.3s 0.15s;
    }
    &:checked + .landing_header__burger::after {
      bottom: 11px;
      transform: rotate(-45deg);
      transition: bottom 0.3s, transform 0.3s 0.15s;
    }
  }
  &__burger {
    margin-left: 20px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: block;
    position: relative;
    border: none;
    background: transparent;
    width: 40px;
    height: 26px;
    margin-top: 12px;
    &::before,
    &::after {
      content: '';
      left: 0;
      position: absolute;
      display: block;
      width: 100%;
      height: 4px;
      border-radius: 10px;
      background: #000;
    }
    &::before {
      top: 5px;
      // box-shadow: 0 11px 0 #000;
      transition: box-shadow 0.3s 0.15s, top 0.3s 0.15s, transform 0.3s;
    }
    &::after {
      bottom: 5px;
      transition: bottom 0.3s 0.15s, transform 0.3s;
    }
  }
}
</style>
