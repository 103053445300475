import { render, staticRenderFns } from "./LayoutTariffs.vue?vue&type=template&id=72f81f03&scoped=true"
import script from "./LayoutTariffs.vue?vue&type=script&lang=js"
export * from "./LayoutTariffs.vue?vue&type=script&lang=js"
import style0 from "./LayoutTariffs.vue?vue&type=style&index=0&id=72f81f03&prod&scoped=true&lang=css"
import style1 from "./LayoutTariffs.vue?vue&type=style&index=1&id=72f81f03&prod&lang=css"
import style2 from "./LayoutTariffs.vue?vue&type=style&index=2&id=72f81f03&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72f81f03",
  null
  
)

export default component.exports