<template>
  <div class="table">
    <NewModalSettings
      v-if="columns.length"
      pageName="companiesAdvertising"
      :columns="columns"
      :showZeros="getShowZeros()"
      @setShowZeros="setShowZeros"
      @setColumns="setColumns"
      ref="settingsModal"
    />
    <div
      v-if="tooltipOptions"
      :style="{
        'z-index': 1000,
        position: 'fixed',
        left: tooltipOptions.x + 'px',
        top: tooltipOptions.y + 'px',
      }"
    >
      <NewImgPreview :imageUrl="tooltipOptions.imageUrl" />
    </div>
    <ag-grid-vue
      class="ag-theme-alpine custom-grid"
      style="width: 100%; height: 100%"
      :columnDefs="columns"
      :rowData="items"
      :gridOptions="gridOptions"
      :defaultColDef="defaultColDef"
      :pinnedBottomRowData="getPinnedBottomRowData()"
      :tooltipShowDelay="0"
      @grid-ready="onGridReady"
      @filter-changed="onFilterChanged"
    ></ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions } from 'vuex';
import TableHeader from '@/components/ui/table/TableHeader.vue';
import TableFilterModal from '@/components/ui/table/TableFilterModal.vue';
import NewModalSettings from '@/components/ui/table/NewModalSettings.vue';
import TableHeaderSettings from '@/components/ui/table/TableHeaderSettings.vue';
import NewImgPreview from '@/components/images/NewImgPreview.vue';
import StatusCell from '@/components/ui/table/StatusCell.vue';
import ImgListCell from '@/components/ui/table/ImgListCell.vue';
import LinkCell from '@/components/ui/table/LinkCell.vue';
import { numberFormatter } from '@/helpers/numberFormatter';

export default {
  name: 'Table',
  props: ['items', 'totalsItems'],
  components: {
    AgGridVue,
    agColumnHeader: TableHeader,
    TableFilterModal,
    NewModalSettings,
    TableHeaderSettings,
    NewImgPreview,
    StatusCell,
    ImgListCell,
    LinkCell,
  },
  data() {
    return {
      tooltipOptions: null,
      columns: [],
      gridOptions: {},
      defaultColDef: {
        tooltipMouseTrack: true,
        resizable: true,
        width: 150,
        filter: false,
        sortable: true,
        unSortIcon: true,
        cellDataType: false,
        alwaysShowHorizontalScroll: true,
        alwaysShowVerticalScroll: true,
        headerComponentParams: {
          pageName: 'companiesAdvertising',
        },
      },
      gridApi: null,
    };
  },
  computed: {
    ...mapGetters('companiesAdvertising', ['options']),
  },
  methods: {
    ...mapActions('companiesAdvertising', ['getData', 'setOptions']),
    onFilterChanged() {
      const filterModel = this.gridApi.getFilterModel();
      const totalsKeyList = Object.keys(this.totalsItems);
      const totalsItems = totalsKeyList.reduce(
        (acc, key) => ({
          ...acc,
          [key]: 0,
        }),
        {}
      );
      this.gridApi.forEachNodeAfterFilterAndSort((node) => {
        totalsKeyList.forEach((key) => {
          totalsItems[key] += node.data[key];
        });
      });
      this.gridApi.setPinnedBottomRowData([totalsItems]);
      localStorage.setItem(
        `companiesAdvertising-filters`,
        JSON.stringify(filterModel)
      );
    },
    setShowZeros(show_zeros) {
      this.setOptions({
        show_zeros,
      });
      this.getData();
    },

    setTooltipOptions(options) {
      this.tooltipOptions = options;
    },
    resetFilters() {
      this.gridApi.setFilterModel(null);
    },
    showSettingsModal() {
      this.$refs.settingsModal.open();
    },

    setColumns(columns) {
      this.gridApi.setGridOption('columnDefs', columns);
    },

    getShowZeros() {
      return this.options?.show_zeros ?? true;
    },

    getPinnedBottomRowData() {
      return [this.totalsItems];
    },

    generateTableColumns() {
      const savedSorting = localStorage.getItem('companiesAdvertising-sort');
      this.columns = [
        {
          headerGroupComponent: 'TableHeaderSettings',
          headerGroupComponentParams: {
            showSettingsModal: this.showSettingsModal,
          },
          groupName: 'settings',
          children: [
            {
              field: 'status',
              headerName: 'Статус',
              cellRenderer: 'StatusCell',
              name: 'status',
              pinned: 'left',
              filter: 'TableFilterModal',
              headerTooltip:
                'Статус Вашей рекламной кампании (зеленый- РК активна, оранжевый- РК приостановлена, серый- РК завершена (в архиве))',
            },
            {
              field: 'category_name',
              headerName: 'Тип',
              name: 'category_name',
              pinned: 'left',
              filter: 'TableFilterModal',
              headerTooltip:
                'Тип Вашей рекламной кампании (АРК-Автоматическая кампания, Поиск+Каталог)',
            },
            {
              field: 'campaign_name',
              headerName: 'Кампания',
              name: 'campaign_name',
              pinned: 'left',
              filter: 'TableFilterModal',
              cellRenderer: 'LinkCell',
              headerTooltip:
                'Название Вашей рекламной кампании (из рекламного кабинета)',
              cellRendererParams: (params) => {
                return {
                  link: `/reports/statistic-advertising/${params.node.data.campaign_id}`,
                  targetBlank: true,
                };
              },
            },
            {
              field: 'campaign_id',
              name: 'campaign_id',
              headerName: 'Id',
              pinned: 'left',
              sortable: false,
              headerTooltip: 'ID кампании (из рекламного кабинета)',
            },
            {
              field: 'photos',
              name: 'photos',
              cellRenderer: 'ImgListCell',
              cellRendererParams: {
                setTooltipOptions: this.setTooltipOptions,
              },
              headerName: 'Фото',
              sortable: false,
            },
            {
              field: 'brands',
              name: 'brands',
              headerName: 'Бренд',
              filter: 'TableFilterModal',
            },
            {
              field: 'create_date',
              name: 'create_date',
              headerName: 'Старт',
              headerTooltip: 'Дата первого запуска рекламной кампании',
            },
            {
              field: 'finish_date',
              name: 'finish_date',
              headerName: 'Финиш',
              headerTooltip: 'Дата завершения рекламной кампании',
            },
            {
              field: 'views',
              name: 'views',
              headerName: 'Просмотры',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Количество единиц показов товара/товаров рекламируемых в рекламной кампании за выбранный период',
            },
            {
              field: 'clicks',
              name: 'clicks',
              headerName: 'Клики',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Количество единиц кликов (переходов клиента в карточку товара) по товару/товарам рекламируемых в рекламной кампании за выбранный период',
            },
            {
              field: 'cpc',
              name: 'cpc',
              headerName: 'CPC',
              valueFormatter: numberFormatter,
              headerTooltip:
                'CPC (сost per click)- цена за 1 клик (переход клиента в карточку ) в рекламной кампании за выбранный период',
            },
            {
              field: 'ctr',
              name: 'ctr',
              headerName: 'CTR',
              valueFormatter: numberFormatter,
              headerTooltip:
                'CTR (Click-Through Rate) - соотношение количества кликов к показам в процентах. (считаем формулой "(количество кликов / количество показов) х 100%)',
            },
            {
              field: 'bid',
              name: 'bid',
              headerName: 'Ставка',
            },
            {
              field: 'cr',
              name: 'cr',
              headerName: 'CR',
              valueFormatter: numberFormatter,
              headerTooltip:
                'CR (Conversion rate) Соотношение количества кликов к количеству заказов. (считаем формулой (количество кликов/ количество показов) х 100%)',
            },
            {
              field: 'total',
              name: 'total',
              headerName: 'Расход',
              valueFormatter: numberFormatter,
              sort: 'desc',
              headerTooltip:
                'Расход денежных средств  за выбранный период относительно рекламной кампании. (из рекламной статистики ВБ)',
            },
            {
              field: 'financial_consumption',
              name: 'financial_consumption',
              headerName: 'Финансовый расход',
              valueFormatter: numberFormatter,
            },
            {
              field: 'cart',
              name: 'cart',
              headerName: 'Корзина',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Количество единиц добавленых товаров в корзину по рекламной кампании за выбранный период. (из рекламной статистики ВБ)',
            },
            {
              field: 'orders',
              name: 'orders',
              headerName: 'Заказы по РК',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Количество единиц заказанных товаров по рекламной кампании за выбранный период. (из рекламной статистики ВБ)',
            },
            {
              field: 'sum_price_without_spp',
              name: 'sum_price_without_spp',
              headerName: 'Заказы по РК, руб',
              valueFormatter: numberFormatter,
            },
            {
              field: 'drr',
              name: 'drr',
              headerName: 'ДРР, %',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Доля рекламных расходов. Процент трат на рекламу относительно заказов по рекламной кампании за выбранный период (считаем формулой (расходы на рекламу/заказы по рекламе в руб)*100%)',
            },
            {
              field: 'cpl',
              name: 'cpl',
              headerName: 'CPL',
              valueFormatter: numberFormatter,
              headerTooltip:
                '(«Cost per lead» - цена за лид). Стоимость одного добавления в корзину по рекламной кампании за выбранный период. (считаем формулой "расход,руб / на корзины в шт)',
            },
            {
              field: 'cpo',
              name: 'cpo',
              headerName: 'CPO',
              valueFormatter: numberFormatter,
              headerTooltip:
                '(«Cost per Order» - стоимость оформленного заказа). Стоимость одного оформленного заказа по рекламной кампании за выбранный период. (считаем формулой "расход,руб / на заказы в шт)',
            },
            {
              field: 'cpm',
              name: 'cpm',
              headerName: 'CPM',
              valueFormatter: numberFormatter,
              headerTooltip:
                'CPM (Cost Per Mille или Cost Per Millenium) — стоимость тысячи показов рекламного объявления. Ставка, которую Вы указали в рекламном кабинете по рекламной кампании за выбранный период',
            },
            {
              field: 'working_days',
              name: 'working_days',
              headerName: 'Дни работы',
              valueFormatter: numberFormatter,
              headerTooltip:
                'Дни работы относительно каждой рекламной кампании',
            },
            {
              field: 'limit_consumption',
              name: 'limit_consumption',
              headerName: 'Расход лимита',
              valueFormatter: numberFormatter,
            },
          ],
        },
      ];
      if (savedSorting) {
        const parsedSorting = JSON.parse(savedSorting);
        this.columns = this.getSortColumns(this.columns, parsedSorting);
      }
    },
    getSortColumns(columns, parsedSorting) {
      return columns.reduce((acc, column) => {
        if (column.children) {
          column.children = this.getSortColumns(column.children, parsedSorting);
          return [...acc, column];
        }
        return column.field === parsedSorting.order_field
          ? [
              ...acc,
              {
                ...column,
                sort: parsedSorting.order_type,
              },
            ]
          : [...acc, column];
      }, []);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.generateTableColumns();
      const savedFilters = localStorage.getItem(`companiesAdvertising-filters`);
      if (savedFilters) {
        setTimeout(() => {
          this.gridApi.setFilterModel(JSON.parse(savedFilters));
        }, 0);
      }
    },
  },
};
</script>
<style lang=""></style>
