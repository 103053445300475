<script setup>
import { defineProps, ref } from 'vue';

const props = defineProps({
  options: {
    type: Array,
    required: false,
    default: [],
  },
  onSelect: {
    type: Function,
    required: false,
  },
});

const activeOption = ref(null);

const onSelectOption = (option) => {
  activeOption.value = option.key;

  if (props.onSelect) props.onSelect(option);
};
</script>

<template>
  <div id="ReportsDropDown" class="reports_group_drop_down">
    <ul class="reports_group_drop_down__list">
      <li v-for="option in props.options" :key="option.key">
        <a
          class="reports_group_drop_down__option"
          :class="{ active: activeOption === option.key }"
          @click="onSelectOption(option)"
        >
          {{ option.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
$colorActive: #481173;

.reports_group_drop_down {
  position: absolute;
  top: calc(100% + 15px);
  right: 0;
  max-height: 200px;
  width: 215px;
  padding: 12px 16px;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(47, 10, 73, 0.12);
  z-index: 999;
  text-align: left;
  background: #fff;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;
    list-style: none;
  }

  &__option {
    padding-bottom: 4px;
    border-bottom: 1px solid transparent;
    color: #160027;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;


    &:hover, &:active, &.active {
      color: $colorActive;
      border-bottom: 1px solid $colorActive;
    }
  }
}
</style>
