<template lang="">
  <div class="preview">
    <img
      class="preview__img"
      v-if="params"
      :src="params.value"
      alt="Tooltip image"
    />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.preview {
  height: 172px;
  width: 136px;

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: drop-shadow(0px 0px 8px rgba(47, 10, 73, 0.12));
  }
}
</style>
