<script setup>
import axios from 'axios';

const { method, onUpdate } = defineProps({
  method: {
    type: Object,
    required: true,
  },
  onUpdate: {
    type: Function,
    required: true,
  },
});

const removeMethod = () => {
  axios({
    url: `/payment-token/delete/${method.id}/`,
    method: 'DELETE',
  }).then(() => {
    onUpdate();
  });
};

const setDefault = () => {
  if (!method.is_default) {
    axios({
      url: `/payment-token/make-default/${method.id}/`,
      method: 'POST',
    }).then((response) => {
      onUpdate();
    });
  }
};

// /img/payment-tinkoff-icon.svg
</script>

<template>
  <div class="payment_method">
    <div class="payment_method__info">
      <img :src="method.img" :alt="method.title" v-if="false" />

      <h6 class="payment_method__title">{{ method.name }}</h6>

      <span class="payment_method__default" v-if="method.is_default"
        >(по умолчанию)</span
      >
    </div>

    <div>
      <b-dropdown class="payment_method__control" no-caret>
        <template #button-content>
          <b-icon-three-dots></b-icon-three-dots>
        </template>

        <b-dropdown-item-button @click="setDefault" v-if="!method.is_default">
          По умолчанию
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="removeMethod">
          Удалить
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
  </div>
</template>

<style lang="scss">
.payment_method {
  margin-bottom: .5rem;
  display: grid;
  align-items: center;
  grid-template-columns: 300px auto;
  gap: 12px;

  &__info {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    margin-bottom: 0;
    color: #160027;
    font-size: 18px;
    font-weight: 400;
  }

  &__default {
    color: #908F90;
    font-size: 14px;
  }
}

.payment_method__control {
  & .dropdown-toggle {
    height: 36px;
    width: 36px;
    padding: 0;
    border-radius: 7px;
    border: 1px solid #F1F1F1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 12px;
    background: #FFF;
  }

  & .dropdown-menu {
    top: -45px !important;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 0px 8px 0px rgba(47, 10, 73, 0.12);
  }
}
</style>
