var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 default-layout d-flex flex-column"},[_c('b-navbar',{staticClass:"wb-header p-0 dashboard__navbar"},[_c('b-container',{staticClass:"px-5 h-100",attrs:{"fluid":""}},[_c('b-navbar-brand',{staticClass:"p-0",attrs:{"href":"/"}},[_c('b-img',{attrs:{"src":"/img/logo.svg","width":"121","height":"40"}})],1),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{staticClass:"pl-4 h-100",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"h-100"},[_c('b-nav-item',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v(" Дашборд ")]),_c('b-nav-item',{attrs:{"to":{ name: 'organizations' }}},[_vm._v(" Организации ")]),_c('b-nav-item-dropdown',{staticClass:"reports-dropdown",attrs:{"right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Отчеты "),_c('img',{staticClass:"ml-2 wb-dropdown-icon",attrs:{"src":"/img/chevron-down.svg"}})]},proxy:true}])},[_c('b-dropdown-item',{class:[
                _vm.current_report === 'productsAdvertising' ? 'active-item' : '',
              ],on:{"click":function($event){return _vm.goToReport('productsAdvertising')}}},[_vm._v("1. Все товары в рекламе")]),_c('b-dropdown-item',{class:[
                _vm.current_report === 'CompaniesAdvertising'
                  ? 'active-item'
                  : '',
              ],on:{"click":function($event){return _vm.goToReport('CompaniesAdvertising')}}},[_vm._v("2. Все рекламные кампании")]),_c('b-dropdown-item',{class:[_vm.current_report === 'dyn_orders' ? 'active-item' : ''],on:{"click":function($event){return _vm.goToReport('dyn_orders')}}},[_vm._v("5. Отчёт РнП")])],1)],1),_c('b-navbar-nav',{staticClass:"ml-auto h-100"},[_c('b-nav-item-dropdown',{staticClass:"nav_item_dropdown",attrs:{"right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.users[0] && _vm.currentOrganization)?_c('span',{staticClass:"truncate-text"},[_vm._v(_vm._s(_vm.currentOrganization.fullname))]):_c('span',{staticClass:"truncate-text"},[_vm._v("Ваши организации")]),_c('img',{staticClass:"ml-3 wb-dropdown-icon",attrs:{"src":"/img/chevron-down.svg"}})]},proxy:true}])},_vm._l((_vm.users),function(user){return _c('b-dropdown-item',{key:user.id,attrs:{"href":"#"},on:{"click":function($event){return _vm.selectUser(user)}}},[_vm._v(_vm._s(user.fullname))])}),1),_c('b-nav-item-dropdown',{staticClass:"user-actions",attrs:{"right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-img',{attrs:{"src":"/img/hat.png","width":"48","height":"48"}}),_c('img',{staticClass:"ml-3 wb-dropdown-icon",attrs:{"src":"/img/chevron-down.svg"}})]},proxy:true}])},[_c('b-dropdown-item',[_c('router-link',{staticClass:"link_profile",attrs:{"to":"/profile"}},[_vm._v(" Профиль ")])],1),_c('b-dropdown-item',{staticClass:"logout",attrs:{"href":"#"},on:{"click":_vm.logout}},[_vm._v("Выйти")])],1)],1)],1)],1)],1),_c('main',{staticClass:"wb-main layout_tariffs__container"},[_c('div',{staticClass:"layout_tariffs__right_block"}),_c('b-container',{staticClass:"px-4 d-flex flex-column flex-grow-1",attrs:{"fluid":""}},[_c('div',{staticClass:"flex-grow-1",attrs:{"id":"content"}},[_c('b-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }