<script setup>
import { defineProps } from 'vue';
import { getFixedValue } from '@/middleware/formatted-value';
import { renderPrice } from '@/utils/prices';

const props = defineProps({
  report: Object,
});

const renderRedeementRub = (item) => {
  console.log('renderRub');

  const value = getFixedValue(item.redeemed_rub);

  return renderPrice(value);
};
</script>

<template>
  <div class="dashboard_report">
    <h3 class="dashboard_report__title">{{ props.report.title }}</h3>

    <div class="table-responsive dashboard_report__wrap_table">
      <table class="dashboard_report__table">
        <thead>
          <tr>
            <th
              v-for="th in props.report.table.headers"
              :key="th.key"
              :class="{ w_20: th.small }"
            >
              {{ th.text }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="item in props.report.table.data" :key="item.id">
            <td v-for="th in props.report.table.headers" :key="th.key">
              <span v-if="th.key === 'redeemed_rub'">
                {{ renderRedeementRub(item) }}
              </span>
              <span v-else>
                {{ getFixedValue(item[th.key]) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dashboard_report {
    &__title {
      margin-bottom: 40px;
      color: #160027;
      font-size: 24px;
      font-weight: 600;
    }

    &__wrap_table {
      padding: 16px 0;
      background: #fff;
    }

    &__table {
      width: 100%;

      & thead {
        & th {
          padding: 14px;
          color: #160027;
          font-size: 17px;
          font-weight: 600;
          text-align: center;

          &.w_20 {
            width: 20ch;
          }
        }
      }

      & tbody {
        & tr {
          border-bottom: 1px solid rgba(22,0,39, .3);

          & td {
            padding: 14px;
            text-align: center;
            color: #160027;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
</style>
