export const mapObjectToFixed = (value) => {
  return Object.entries(value).reduce((acc, [key, val]) => {
    if (val && typeof val === 'object' && !Array.isArray(val)) {
      const mappedValue = mapObjectToFixed(val);
      return {
        ...acc,
        [key]: mappedValue,
      };
    }
    if (!isNaN(+val)) {
      return {
        ...acc,
        [key]: parseInt(100 * +val) / 100,
      };
    }
    return {
      ...acc,
      [key]: val,
    };
  }, {});
};
