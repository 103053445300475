<template lang="">
  <div class="status-container">
    <div class="status" :class="{ [params.value]: true }"></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.status-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  background-color: white;
  &.stopped {
    background-color: rgb(153, 153, 153);
  }
  &.warning {
    background-color: rgb(251, 140, 0);
  }
  &.success {
    background-color: rgb(76, 175, 80);
  }
}
</style>
