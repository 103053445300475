<script setup>
import { getCurrentInstance, ref } from 'vue';
import moment from 'moment/moment';

const props = defineProps({
  dateEnd: {
    type: String,
    required: false,
    default: null,
  },
  isShowHoverEffect: {
    type: Boolean,
    required: false,
    default: true,
  },
  tariff: {
    type: Object,
    required: true,
  },
  isMy: {
    type: Boolean,
    required: false,
    default: false,
  },
  onSubscript: {
    type: Function,
    required: false,
  },
  isAddAccount: {
    type: Boolean,
    required: false,
    default: false,
  },
  currentUserTariffInfo: {
    type: Object,
    required: false,
    default: {},
  },
  addAccount: {
    type: Function,
    required: false,
  },
  isWaiting: {
    type: Boolean,
    required: false,
    default: false,
  },
  currentTariff: {},
});
const isCanChangeSubscription = ref(true);

const getDateEnd = () => {
  if (props.dateEnd) {
    return moment(props.dateEnd).format('DD.MM.YYYY');
  }

  return '';
};

const renderTextIsMy = () => {
  if (props.isAddAccount) {
    return 'Привязать счёт';
  }

  return !props.currentUserTariffInfo.current_subscription.is_active
    ? 'Возобновить подписку'
    : 'Отменить подписку';
};

const renderLink = () => {
  let link = '/unsubscribe';

  if (!props.currentUserTariffInfo.current_subscription.is_active)
    return '/unsubscribe?recovery';

  return link;
};

const accept = () => {
  bvModal.hide(`${props.tariff.id}-ModalSugest`);

  if (props.onSubscript && isCanChangeSubscription.value) {
    isCanChangeSubscription.value = false;
    props.onSubscript(props.tariff);

    setTimeout(() => {
      isCanChangeSubscription.value = true;
    }, 5000);
  }
};

const isShowModal = ref(true);
const currentTariff = ref({});

const handleSubscript = () => {
  setTimeout(() => {
    currentTariff.value = Object.assign({}, props.tariff);
    bvModal.show(`${props.tariff.id}-ModalSugest`);
  }, 1000);
};

const modalCloseHandler = () => {
  isShowModal.value = false;
  bvModal.hide(`${props.tariff.id}-ModalSugest`);
};

const bvModal = getCurrentInstance().proxy.$root.$bvModal;
</script>

<template>
  <div class="profile_offer" :class="{ new: !props.isMy }">
    <div v-if="props.tariff">
      <b-modal
        :id="`${props.tariff.id}-ModalSugest`"
        @hide="modalCloseHandler"
        centered
      >
        <template #modal-title> Подписка </template>

        <div>
          <p class="text-center" v-if="currentTariff">
            Вы уверены что хотите оформить подписку {{ currentTariff.name }}?
          </p>
        </div>

        <template #modal-footer>
          <div class="modal-footer-suqest">
            <button
              class="btn btn-cancel btn-cancel-suqest"
              @click="modalCloseHandler()"
            >
              Отменить
            </button>
            <button class="btn btn-primary btn-accept-suqest" @click="accept">
              Принять
            </button>
          </div>
        </template>
      </b-modal>
    </div>

    <div class="profile_offer__grid">
      <div class="profile_offer__info">
        <h6 class="profile_offer__info__title">
          {{ props.tariff.name }}
        </h6>

        <h5 class="profile_offer__info__price" v-if="props.tariff.price_rub">
          {{ Number(props.tariff.price_rub) }}₽/месяц
        </h5>
        <p
          v-if="props.tariff.old_price_rub !== props.tariff.price_rub"
          class="profile_offer__info__old-price"
        >
          {{ props.tariff.old_price_rub }}₽/месяц
        </p>
        <p
          class="profile_offer__info__text"
          v-if="!props.isMy && tariff.can_be_taken"
        >
          При повышении тарифа вы оплчиваете часть стоимости, зависящую от
          остатка текущей подписки
        </p>

        <div
          class="profile_offer__info__date"
          v-if="props.isMy && !props.isWaiting"
        >
          Дата окончания: {{ getDateEnd() }}
        </div>

        <template>
          <span v-if="tariff.can_be_taken">
            <a
              class="profile_offer__info__unsubscribe"
              :href="renderLink()"
              v-if="props.isMy"
            >
              {{ renderTextIsMy() }}
            </a>
            <a
              class="profile_offer__info__try"
              :class="{ disabled: !isCanChangeSubscription }"
              v-if="!props.isMy"
              @click="handleSubscript"
            >
              {{ isAddAccount ? 'Привязать счет' : 'Оплатить' }}
            </a>

            <p v-if="props.isAddAccount" class="tariff-description">
              После привязки счета нужно будет еще раз выбрать тариф и оплатить
            </p>
          </span>
          <span class="text-empty-subscription" v-else>
            <p
              style="cursor: help"
              title="Чтобы перейти на более низкий тариф, отмените текущую подписку и в следующем месяце оформите другой тариф."
            >
              Понижение тарифного плана невозможно.
            </p>
          </span>
        </template>

        <template v-if="props.isWaiting">
          <a class="profile_offer__info__try"> Ожидание </a>
        </template>
      </div>

      <div class="profile_offer__plan">
        <h6 class="profile_offer__plan__title">План включает:</h6>

        <ul class="profile_offer__plan__list">
          <li
            v-for="(item, key) in props.tariff.listOfferInclude"
            :key="key"
            class="profile_offer__plan__list__item"
          >
            <img
              :src="
                props.isMy
                  ? '/img/profile-benefite-icon.svg'
                  : '/img/profile-benefite-icon-white.svg'
              "
              alt=""
            />
            <div>
              {{ item }}

              <span v-if="!['advertisement'].includes(key)">
                {{ props.tariff[key] }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.profile_offer {
  position: relative;
  height: 285px;
  padding: 20px 1rem;
  box-shadow: 0 0 8px 0 rgba(47, 10, 73, 0.12);
  border-radius: 10px;
  overflow: hidden;
  background: #fff;

  &__grid {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  &__info {
    position: relative;
    padding-right: 30px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: calc(100% - 20px);
      width: 1px;
      background: #f1f1f1;
    }

    &__title {
      color: #160027;
      font-size: 18px;
    }

    &__price {
      margin: 12px 0;
      font-size: 30px;
      font-weight: 500;
      overflow: hidden;
      word-break: break-all;
      width: 100%;
      white-space: nowrap;
    }

    &__old-price {
      color: #908f90;
      font-size: 16px;
      text-decoration: line-through;
    }

    &__text {
      color: #908f90;
      font-size: 10px;
      line-height: 130%;
    }

    &__date {
      margin-top: 6rem;
      margin-bottom: 0.5rem;
      color: #908f90;
      font-size: 14px;
    }

    &__unsubscribe {
      color: #908f90;
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
      cursor: pointer;
    }

    &__try {
      height: 36px;
      width: 180px;
      margin-top: 40px;
      border-radius: 1000px;
      color: #481173;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      cursor: pointer;
      background: #fff;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  &__plan {
    padding-left: 30px;

    &__title {
      margin-bottom: 1rem;
      font-size: 16px;
      font-weight: 600;
    }

    &__list {
      list-style: none;
      padding-left: 0;

      &__item {
        margin-bottom: 12px;
        display: flex;
        align-items: start;
        gap: 10px;
        color: #160027;
        font-size: 16px;

        & img {
          position: relative;
          top: 4px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.profile_offer.new {
  background: #481173;

  & .profile_offer__info__title,
  & .profile_offer__info__price,
  & .profile_offer__plan__title,
  & .profile_offer__plan__list__item {
    color: #fff;
  }

  & .profile_offer__info__price {
    margin-bottom: 0;
  }
}

.profile_offer__info__try.disabled {
  background: #ccc !important;
}

.tariff-description {
  color: #908f90;
  padding: 1.3rem 0;
  font-size: 13px;
}

.text-empty-subscription {
  margin-top: 2rem;
  color: #fff;
  font-size: 13px;
  display: inline-block;
}

.btn-accept-suqest {
  border: 1px solid #481173 !important;
  background: #481173 !important;
}

.btn-cancel-suqest {
  color: #908f90 !important;
}
</style>
