<template lang="">
  <div>
    <b-btn
      class="btn_icon btn_settings_columns table-v2-btn-settings"
      @click="click"
    >
      <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
    </b-btn>
  </div>
</template>
<script>
export default {
  name: 'TableHeaderSettings',
  props: ['showSettingsModal'],
  methods: {
    click() {
      this.params.showSettingsModal();
    },
  },
};
</script>
<style lang=""></style>
