<template>
  <div class="table-header" :class="tooltipClass">
    <div class="table-header__label">{{ params.displayName }}</div>
    <div v-if="params.enableSorting">
      <div
        v-if="ascSort"
        @click="onSortRequested('desc', $event)"
        class="customSortDownLabel"
      >
        <img
          height="18"
          class="ml-1 rotated"
          src="@/assets/img/SortDescending.svg"
        />
      </div>
      <div
        v-if="descSort"
        @click="onSortRequested('asc', $event)"
        class="customSortUpLabel"
      >
        <img height="18" class="ml-1" src="@/assets/img/SortDescending.svg" />
      </div>
      <div
        v-if="noSort"
        @click="onSortRequested('asc', $event)"
        class="customSortRemoveLabel"
      >
        <img height="18" class="ml-1" src="@/assets/img/Sort-Default.svg" />
      </div>
    </div>
    <div
      ref="menuButton"
      @click="onMenuClicked($event)"
      v-if="params.enableMenu"
    >
      <img v-if="!activeFilter" height="18" src="@/assets/img/Funnel.svg" />
      <img v-if="activeFilter" src="@/assets/img/Funnel-active.svg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableHeader',
  data() {
    return {
      ascSort: false,
      descSort: false,
      noSort: false,
      activeFilter: false,
    };
  },
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
    this.params.column.addEventListener('filterChanged', this.onFilterChanged);
    this.activeFilter = this.params.column.isFilterActive();
  },
  beforeUnmount() {
    this.params.column.removeEventListener('sortChanged', this.onSortChanged);
    this.params.column.removeEventListener(
      'filterChanged',
      this.onFilterChanged
    );
  },
  computed: {
    tooltipClass() {
      if (this.params.column.colDef.headerTooltip) {
        return ['cursor-help'];
      }
    },
  },
  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    onSortRequested(order, event) {
      localStorage.setItem(
        `${this.params.pageName}-sort`,
        JSON.stringify({
          order_type: order,
          order_field: this.params.column.getColDef().field,
        })
      );
      this.params.setSort(order, event.shiftKey);
    },

    onSortChanged() {
      this.ascSort = this.descSort = this.noSort = false;
      const sort = this.params.column.getSort();
      if (sort === 'asc') {
        this.ascSort = true;
      } else if (sort === 'desc') {
        this.descSort = true;
      } else {
        this.noSort = true;
      }
    },

    onFilterChanged() {
      this.activeFilter = this.params.column.isFilterActive();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img.rotated {
    transform: rotate(180deg);
  }
  &__label {
    margin-right: 5px;
  }
}

.cursor-help {
  cursor: help;
}
</style>
