<template>
  <div v-if="options" class="filter-sort">
    <div class="filter-sort__range">
      <div class="d-flex period-filter me-3">
        <label class="d-flex align-items-center me-3 mb-0">Период</label>
        <b-input-group style="width: 160px">
          <b-input-group-prepend
            class="third-report-card_sort-and-filter__input-prepend"
          >
            <b-form-datepicker
              start-weekday="1"
              button-only
              @input="changeDate($event, 'start_date')"
            >
              <template #button-content>
                <img height="20" src="/img/datepicker-icon.svg" />
              </template>
            </b-form-datepicker>
          </b-input-group-prepend>
          <b-form-input
            class="third-report-card_sort-and-filter__input"
            type="text"
            placeholder="17/02/2023"
            v-model="formattedFrom"
            disabled
            style="height: 45px"
          ></b-form-input>
        </b-input-group>
      </div>
      <div class="d-flex period-filter">
        <label class="d-flex align-items-center me-3 mb-0">по</label>
        <b-input-group style="width: 160px">
          <b-input-group-prepend
            class="third-report-card_sort-and-filter__input-prepend"
          >
            <b-form-datepicker
              start-weekday="1"
              button-only
              @input="changeDate($event, 'end_date')"
            >
              <template #button-content>
                <img height="20" src="/img/datepicker-icon.svg" />
              </template>
            </b-form-datepicker>
          </b-input-group-prepend>
          <b-form-input
            type="text"
            class="third-report-card_sort-and-filter__input"
            placeholder="17/02/2023"
            v-model="formattedTo"
            disabled
            style="height: 45px"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <div>
      <b-button-group class="wb-button-group">
        <b-button
          style="font-size: 15px"
          :pressed="checkDate(1)"
          @click="setDate(1)"
          >Вчера</b-button
        >
        <b-button
          style="font-size: 15px"
          :pressed="checkDate(7)"
          @click="setDate(7)"
          >7 дней</b-button
        >
        <b-button
          style="font-size: 15px"
          :pressed="checkDate(14)"
          @click="setDate(14)"
          >14 дней</b-button
        >
        <b-button
          style="font-size: 15px"
          :pressed="checkDate(30)"
          @click="setDate(30)"
          >30 дней</b-button
        >
      </b-button-group>
    </div>
    <div class="button-block">
      <ReportsGroupBtnMenu :options="groups" :onSelect="selectGroupOption" />
      <b-btn @click="downloadReport" class="wb-btn">
        <img src="@/assets/img/download.svg" /> <span class="ml-2">Export</span>
      </b-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import ReportsGroupBtnMenu from '@/components/reports-groups/ReportsGroupBtnMenu.vue';

export default {
  name: 'filterAndSort',
  components: {
    ReportsGroupBtnMenu,
  },
  data() {
    return {
      groups: [
        { key: 'days', name: 'Группировка по дням' },
        { key: 'weeks', name: 'Группировка по неделям' },
        { key: 'months', name: 'Группировка по месяцам' },
        { key: 'years', name: 'Группировка по годам' },
      ],
      status: null,
      brand: null,
      subject: null,
      article: null,
    };
  },
  computed: {
    ...mapGetters(['currentOrganization']),
    ...mapGetters('companiesAdvertising', ['options', 'filters']),

    formattedTo() {
      return moment(this.options.end_date).format('DD/MM/YYYY');
    },
    formattedFrom() {
      return moment(this.options.start_date).format('DD/MM/YYYY');
    },
  },

  methods: {
    ...mapActions(['getProductsAdvertising', 'uploadReport']),
    ...mapActions('companiesAdvertising', [
      'getData',
      'setOptions',
      'resetOptions',
    ]),

    onOptionsChanged() {
      localStorage.removeItem('companiesAdvertising-filters');
      this.getData();
    },

    changeDate(data, key) {
      this.setOptions({ [key]: data });
      this.onOptionsChanged();
    },

    selectGroupOption(groupBy) {
      this.setOptions({ group_by: groupBy });
      this.onOptionsChanged();
    },

    getOrderBy() {
      const orderBy = localStorage.getItem('companiesAdvertising-sort');
      const filters = {};
      if (orderBy) {
        const parsedOrder = JSON.parse(orderBy);
        const splittedFields = parsedOrder.order_field.split('.');
        const date_interval = splittedFields.find((val) =>
          moment(val, 'YYYY-MM-DD').isValid()
        );
        filters.order_by = {};
        filters.order_by.order_field =
          splittedFields[splittedFields.length - 1];
        filters.order_by.order_type = parsedOrder.order_type.toUpperCase();
        if (date_interval) {
          filters.order_by.date_interval = date_interval;
        }
      }
      return filters;
    },

    downloadReport() {
      const filters = {
        end_date: this.options.end_date,
        start_date: this.options.start_date,
        wb_account: this.currentOrganization.id,
        group_by: this.options.group_by,
        ...this.getOrderBy(),
      };

      this.uploadReport({
        type: 'comm_rk',
        filters: filters,
      });
    },

    clearFilters() {
      this.resetOptions();
    },

    checkDate(interval) {
      let days = interval;
      const now = moment().subtract(1, 'days');
      const pre = moment().subtract(days, 'days');

      return (
        now.isSame(moment(this.options.end_date), 'day') &&
        pre.isSame(moment(this.options.start_date), 'day')
      );
    },
    setDate(interval) {
      const to = moment().subtract(1, 'days').format('YYYY-MM-DD');
      const from = moment().subtract(interval, 'days').format('YYYY-MM-DD');

      this.setOptions({
        end_date: to,
        start_date: from,
      });
      this.onOptionsChanged();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../index.scss';

.third-report-card {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
}

.third-report-card__info {
  justify-content: flex-start;
  padding-left: 1.5rem;
}

.filter_btns {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filter_export {
  display: flex;
  justify-content: flex-end;

  & button {
    height: 40px;
  }
}

.filter-sort {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: #fff;

  &__range {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.button-block {
  display: flex;
}
</style>
