<template lang="">
  <div class="cell-images-container">
    <img
      class="table_img table-v2-img"
      v-for="(image, index) in params.value"
      :key="index"
      :src="image"
      @mouseover="showTooltip(image, $event)"
      @mouseleave="clearTooltip"
    />
  </div>
</template>
<script>
export default {
  methods: {
    showTooltip(imageUrl, event) {
      this.params.setTooltipOptions({
        x: event.clientX,
        y: event.clientY - 180,
        imageUrl,
      });
    },
    clearTooltip() {
      this.params.setTooltipOptions(null);
    },
  },
};
</script>
<style lang="scss">
.cell-images-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  img {
    margin: 0px 3px;
  }
}
</style>
