<template lang="">
  <div class="landing-host-info">
    <p v-for="text in userInfoText" :key="text">{{ text }}</p>
  </div>
</template>
<script setup>
const userInfoText = [
  'Индивидуальный предприниматель Соколова Евгения Геннадьевна',
  'ИНН: 711614562312',
  'ОГРНИП: 315774600180066',
  'Адрес: г. Москва.',
];
</script>
<style lang="scss">
.landing-host-info {
  margin-top: 55px;

  & p {
    margin-bottom: 0;
    padding-bottom: 0;
    color: #908f90;
  }
  @media screen and (max-width: 570px) {
    margin-top: 30px;
  }
}
</style>
