import { render, staticRenderFns } from "./LandingTag.vue?vue&type=template&id=8df42e48&scoped=true"
import script from "./LandingTag.vue?vue&type=script&setup=true&lang=js"
export * from "./LandingTag.vue?vue&type=script&setup=true&lang=js"
import style0 from "./LandingTag.vue?vue&type=style&index=0&id=8df42e48&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8df42e48",
  null
  
)

export default component.exports