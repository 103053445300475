<template>
  <div>
    <div class="wb-report-3-title">
      Общая статистика артикула по активным кампаниям за период
    </div>

    <div class="wb-report-3-chart-1">
      <div class="d-flex justify-content-between title">
        <div>Динамика</div>
        <div>
          ДРР на артикул от суммы заказов по этому артикулу: {{ total_drr }}
        </div>
      </div>

      <!--      <div class="my-4">
              <div class="pre-filters d-flex">
                <div class="d-flex flex-grow-1 align-items-center">
                  <div class="filter-btn active">По артикулу</div>
                  <div class="filter-btn">По рекламным кампаниям</div>
                </div>-->

      <!--          <div>-->
      <!--            <b-btn-->
      <!--              class="wb-btn wb-btn-reverse mr-2 d-inline-block"-->
      <!--              style="display: inline-block !important"-->
      <!--            >-->
      <!--              <img src="/img/eye-icon.svg" />-->
      <!--            </b-btn>-->

      <!--            <b-btn-->
      <!--              class="wb-btn wb-btn-reverse d-inline-block mx-3"-->
      <!--              style="width: 44px; display: inline-block !important"-->
      <!--            >-->
      <!--              <img src="/img/fb-icon.svg" />-->
      <!--            </b-btn>-->

      <!--            <b-btn-->
      <!--              class="wb-btn wb-btn-reverse wb-btn-red d-inline-block"-->
      <!--              style="display: inline-block !important"-->
      <!--            >-->
      <!--              <img src="/img/clear-icon.svg" />-->
      <!--            </b-btn>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="flex-grow-1">
        <LineChart :data="data" />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/charts/LineChart';
import { mapGetters } from 'vuex';

export default {
  name: 'dynamicChart',
  props: {
    data: Object,
  },
  components: {
    LineChart,
  },
  computed: {
    ...mapGetters(['total_drr']),
  },
};
</script>

<style scoped>
.badge {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 5px;
  vertical-align: middle;
}
</style>
