import axios from 'axios';
import { mapProducts, mapTotals } from './mapper';
import moment from 'moment';

const defaultOptions = {
  group_by: 'days',
  order_by: {
    order_type: 'DESC',
    order_field: 'total_in_pieces',
  },
  start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
};

export const actions = {
  getData({ commit, state }) {
    commit('REPORT5_SET_LOADING', true);
    axios({
      url: '/new-reports/dyn_profit/',
      method: 'POST',
      data: state.options,
    })
      .then((response) => {
        commit(
          'REPORT5_SET_ITEMS',
          mapProducts(response.data.products, {
            from: state.options.start_date,
            to: state.options.end_date,
            groupBy: response.data.group_by,
          })
        );
        commit(
          'REPORT5_SET_TOTAL_ITEMS',
          mapTotals(response.data.totals, {
            from: state.options.start_date,
            to: state.options.end_date,
            groupBy: response.data.group_by,
          })
        );
      })
      .catch((err) => {
        console.error(err);
        commit('REPORT5_TO_INITIAL_VALUES');
      })
      .finally(() => {
        commit('REPORT5_SET_LOADING', false);
      });
  },

  setDefaultOptions({ commit }, id) {
    const storageOptions = localStorage.getItem('report5-options');
    const options = storageOptions
      ? JSON.parse(storageOptions)
      : defaultOptions;
    commit('SET_OPTIONS', {
      ...options,
      wb_account: id,
      end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    });
  },

  resetOptions({ commit, state }) {
    const data = { ...state.options, ...defaultOptions };
    commit('SET_OPTIONS', data);
  },

  setOptions({ commit, state }, options) {
    const data = { ...state.options, ...options };
    const { start_date, end_date, wb_account, ...localStorageData } = data;
    localStorage.setItem('report5-options', JSON.stringify(localStorageData));
    localStorage.removeItem('report5-filters');
    localStorage.removeItem('report5-sort');
    commit('SET_OPTIONS', data);
  },

  setFilters({ commit }, filters) {
    localStorage.setItem(`report5-filters`, JSON.stringify(filters));
    commit('SET_FILTERS', filters);
  },
};
