<template>
  <div class="report-5">
    <div class="productsAdvertising-filter wrap_page_filter">
      <filterAndSort></filterAndSort>
    </div>

    <div>
      <b-skeleton-table
        :rows="5"
        v-if="loading"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>

      <span v-else>
        <Table
          v-if="items.length > 0"
          :items="items"
          :totalsItems="totalsItems"
          ref="table"
        >
        </Table>
        <NoData v-else />
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import filterAndSort from './components/filterAndSort';
import NoData from '@/components/no-data/NoData.vue';
import ImgPreview from '@/components/images/ImgPreview.vue';

import { mapGetters, mapActions } from 'vuex';
import { nextTick } from 'vue';
import Table from './components/Table.vue';

export default {
  name: 'index',
  components: {
    Table,
    ImgPreview,
    NoData,
    filterAndSort,
  },
  data() {
    return {
      tableHeight: 0,
    };
  },

  computed: {
    ...mapGetters(['currentOrganization']),
    ...mapGetters('report5', ['totalsItems', 'loading', 'items']),
  },
  watch: {
    items(newItems, oldItems) {
      if (this.$refs.table) {
        this.$refs.table.resetFilters();
      }
    },
    'currentOrganization.id'(newId) {
      this.setOptions({ wb_account: newId });
    },
  },

  methods: {
    ...mapActions('report5', ['setDefaultOptions', 'getData']),
  },
  mounted() {
    this.setDefaultOptions(this.currentOrganization.id);
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';

$borderColor: rgba(0, 0, 0, 0.15);
.image-preview__wrapper {
  position: fixed;
  transform: translateY(-50%);
  z-index: 1000;
}

.col-settings {
  position: sticky;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: start;
}
</style>
