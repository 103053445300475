<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'CurrentTariff',

  props: {
    tariff: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: false,
    },
    isShowFooterControl: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    ...mapGetters(['currentUserTariffInfo']),
  },

  methods: {
    renderEndDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },

    renderPrice(date) {
      if (!date) return 0;

      return Number(date).toFixed(0);
    },

    renderOptions() {
      if (this.options) return this.options;

      return [
        { key: 'org_count', name: 'Организаций:' },
        { key: 'advertisement', name: '5 отчетов' },
        { key: 'dashboard', name: 'Дашборд' },
        { key: 'analytical_data', name: 'Рекламная статистика за всю историю' },
        { key: 'analytical_data2', name: 'Заказы и Продажи за 90 дней' },
      ];
    },

    renderAccounts() {
      let counter = 0;

      if (
        this.currentUserTariffInfo &&
        this.currentUserTariffInfo.current_subscription &&
        this.currentUserTariffInfo.current_subscription.tariff
      ) {
        counter =
          this.currentUserTariffInfo.current_subscription.tariff.org_count;
      }

      return counter;
    },
  },
};
</script>

<template>
  <div class="current-tariff">
    <div class="current-tariff__header">
      <h6 class="current-tariff__name">
        {{ tariff.name }}
      </h6>
      <div class="current-tariff__block-price" v-if="tariff.price">
        <p class="current-tariff__price">{{ renderPrice(tariff.price) }}₽</p>
        <p class="current-tariff__price-description">/месяц</p>
      </div>
    </div>

    <div class="current-tariff__options">
      <h6 class="current-tariff__options__title">План включает:</h6>
      <ul class="current-tariff__options__list">
        <li v-for="option in renderOptions()" :key="option.key">
          <img src="/img/profile-benefite-icon.svg" alt="" />
          <span class="current-tariff__options__name">{{ option.name }}</span>
          <span v-if="option.key === 'org_count'">
            {{ renderAccounts() }}
          </span>
        </li>
      </ul>
    </div>

    <div class="current-tariff__footer">
      <div class="current-tariff__footer__date-end">
        Доступен до {{ renderEndDate(tariff.date_end) }}
      </div>

      <router-link
        to="/tariffs"
        class="current-tariff__footer__control"
        v-if="isShowFooterControl"
      >
        Управление подпиской
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.current-tariff {
  width: 100%;
  max-width: 400px;
  padding: 28px 24px;
  border-radius: 14px;
  box-shadow: 0 0 36px 0 rgba(47, 10, 73, 0.10);
  background: var(--White, #FFF);

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: 15px;
    color: #160027;
    font-size: 24px;
    font-weight: 600;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 29px;
      border-radius: 11px;
      background: var(--WB-Gradient, linear-gradient(103deg, #481173 0%, #CB11AB 100%));
    }
  }

  &__price {
    color: #160027;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
  }

  &__price-description {
    color: #908F90;
    font-size: 16px;
    font-weight: 400;
  }

  &__options {
    margin-bottom: 40px;

    &__title {
      color: #160027;
      font-size: 16px;
      font-weight: 600;
    }

    & ul {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;

      & li {
        display: flex;
        gap: .5rem;
        align-items: flex-start;

        & img {
          position: relative;
          top: 4px;
        }
      }
    }

    &__name {
      color: #160027;
      font-size: 16px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__date-end {
      color: #908F90;
      font-size: 14px;
      font-weight: 400;
    }

    &__control {
      text-decoration: none;

      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }
}
</style>
