<template>
  <div>
    <div class="productStatistic">
      <div class="productStatistic-filter">
        <filterAndSort
          @dateFilterHandler="dateFilterHandler"
          :article="current_id"
          :name="productStatistic.name"
          :img="productStatistic.image"
          :groups="groups"
          :onSelectGroup="onSelectGroup"
          :activeGroup="activeGroup"
          :currentId="current_id"
        ></filterAndSort>
      </div>

      <h3 class="product-statistic__title">
        Общая статистика артикула по активным кампаниям за период
      </h3>

      <div class="dynamic-chart">
        <dynamicChart
          v-if="dynamic_chart.datasets"
          :data="dynamic_chart"
        ></dynamicChart>
      </div>

      <b-skeleton-table
        :rows="5"
        v-if="loading"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>

      <div
        class="productStatistic__tables"
        style="overflow-x: scroll"
        v-if="!loading"
      >
        <totalTable
          :data="totals1"
          :activeGroup="activeGroup"
          :isTotal="true"
          v-if="totals1"
        ></totalTable>

        <totalTable
          v-for="(item, index) in items"
          :key="index"
          :data="item"
          :limit="limit"
          :activeGroup="activeGroup"
        ></totalTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import filterAndSort from './components/filterAndSort.vue';
import totalTable from './components/totalTable.vue';
import dynamicChart from '@/views/reports/productsStatistic/components/dynamicChart';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'index',
  components: {
    filterAndSort,
    totalTable,
    dynamicChart,
  },
  data() {
    return {
      newDirectCompetitor: '',
      isWatchCompetitor: true,
      current_id: null,
      loading: false,
      newKeyword: '',
      from: null,
      to: null,
      fromDefault: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDefault: moment().format('YYYY-MM-DD'),
      groups: [
        { key: 'days', name: 'Группировка по дням' },
        { key: 'weeks', name: 'Группировка по неделям' },
        { key: 'months', name: 'Группировка по месяцам' },
        { key: 'years', name: 'Группировка по годам' },
      ],
      activeGroup: 'days',
      keyboards: [],
      competitors: [],
      keywords: [],
    };
  },
  watch: {
    '$route.query.id': {
      handler(newVal, oldVal) {
        this.getReports();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'productStatistic',
      'dynamic_chart',
      'currentOrganization',
      'totals1',
      'items',
      'productStatisticChart',
      'limit',
    ]),
  },
  methods: {
    ...mapActions([
      'getProductStatistic',
      'postDirectCompetitor',
      'postKeyword',
      'updateDateRangeFromQueryParams',
      'deleteKeyword',
    ]),
    ...mapMutations(['SET_DATE']),

    onSelectGroup(key) {
      this.activeGroup = key;
    },

    formatDate(dateString) {
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      const date = new Date(dateString);
      const dayOfWeek = daysOfWeek[date.getDay()];

      return `${dateString} ${dayOfWeek}`;
    },
    dateFilterHandler(dates) {
      this.to = dates.to;
      this.from = dates.from;
      this.SET_DATE('dates');
      this.getReports();
    },

    onRemoveCompetitor(competitor, index) {
      if (competitor) {
        axios({
          url: `/product/${competitor.id}/delete_competitor/`,
          method: 'DELETE',
        })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Успешно',
            });
            this.competitors.splice(index, 1);
          })
          .catch((err) => {
            console.error(err);
            this.$notify({
              type: 'error',
              title: 'Ошибка',
            });
          });
      }
    },

    async removeKeyword(item, index) {
      axios({
        url: `/product/${item.id}/delete_keyword/`,
        method: 'DELETE',
      })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успешно',
          });

          this.keyboards.splice(index, 1);
        })
        .catch((err) => {
          console.error(err);
          this.$notify({
            type: 'error',
            title: 'Ошибка',
          });
        });
    },

    async addKeyword() {
      if (this.newKeyword && this.newKeyword.trim()) {
        axios({
          url: '/product/add_keyword/',
          method: 'POST',
          data: {
            name: this.newKeyword,
            product: this.productStatistic.id,
          },
        })
          .then(() => {
            this.keyboards.push({
              id: new Date().getTime(),
              name: this.newKeyword,
            });
            this.newKeyword = null;
          })
          .catch((err) => {
            console.error(err);
            this.$notify({
              type: 'Error',
              title: 'Успешно',
            });
          });
      }
    },

    async addDirectCompetitor() {
      if (this.newDirectCompetitor) {
        axios({
          url: '/product/add_competitor/',
          method: 'POST',
          data: {
            nm_id: this.newDirectCompetitor,
            product: this.productStatistic.id,
          },
        })
          .then(() => {
            const newCompetitor = {
              id: new Date().getTime(),
              nm_id: 'nm_id',
              keywords: this.keyboards.slice() || [],
            };
            this.this.newDirectCompetitor = null;
            this.competitors.push(newCompetitor);
          })
          .catch((err) => {
            console.error(err);
            this.$notify({
              type: 'error',
              title: 'Ошибка',
            });
          });
      }
    },

    watchCompetitor() {
      this.isWatchCompetitor = !this.isWatchCompetitor;
    },
    async getReports() {
      this.current_id = this.$route.params.id;
      this.loading = true;
      this.updateDateRangeFromQueryParams(this.$route.query);

      let startDate = this.from
        ? this.from
        : moment().subtract(8, 'days').format('YYYY-MM-DD');
      let endDate = this.to
        ? this.to
        : moment().subtract(1, 'days').format('YYYY-MM-DD');

      let params = {
        start_date: startDate,
        end_date: endDate,
        nm_id: this.current_id,
        account_id: this.currentOrganization.id || 1,
      };
      for (let key of Object.keys(params)) {
        if (!params[key]) {
          delete params[key];
        }
      }

      await this.getProductStatistic(params);
      this.$forceUpdate();
      this.loading = false;
    },
  },
  async mounted() {
    await this.getReports();

    if (Array.isArray(this.productStatistic.keywords_stats)) {
      this.keyboards = this.productStatistic.keywords_stats;
    } else {
      this.keyboards = [];
    }

    if (Array.isArray(this.productStatistic.competitors_stats)) {
      this.competitors = this.productStatistic.competitors_stats;
    } else {
      this.competitors = [];
    }
  },
  created() {
    // Call the action to update the date range based on the router's query parameters
    this.updateDateRangeFromQueryParams(this.$route.query);
  },
};
</script>

<style lang="scss" scoped>
.add-input {
  height: 38px;
  border-left: none !important;
  border-right: 1px solid #ece6f0 !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2221; /* Чтобы заголовок оставался наверху других элементов */
  background-color: #fcf8ff !important;
}
table thead {
  position: sticky;
  top: -1px;
  background-color: white;
  box-shadow: none;
  z-index: 2;
}
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
  background-color: white;
}
.product-statistic-table thead th.sticky-col {
  position: sticky;
  left: 0;
  background-color: #fcf8ff;
  z-index: 1;
}
.table-container {
  overflow: auto;
  position: relative;
}

.product-statistic-table thead:first-child {
  position: sticky;
  top: 0;
  background: #fcf8ff;
  z-index: 1;
}
table thead {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
}
thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
}
/* Задаем стили для первой колонки */
table tbody td.sticky-col {
  position: sticky;
  left: -1px;
  z-index: 1;
  background-color: white;
}

.table-scroll {
  display: grid;
  grid-template-columns: auto repeat(auto-fit, minmax(100px, 1fr));
  max-width: 100%;
  overflow-x: scroll;
}

.productStatistic .td_product_images {
  z-index: 9 !important;

  & .preview {
    left: 0;
    top: calc(100% + 1rem);
  }
}

.productStatistic__tables {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.product-statistic-extra-table {
  position: relative;
  top: -36px;

  & thead th {
    &:first-child {
      width: 26ch;
    }
  }

  & .product-statistic-extra-table-label {
    margin: 0;
  }

  & .wrap_th_competitive {
    display: flex;
    align-items: center;
  }
}

@import './index.scss';

.product-statistic__title {
  margin: 40px 0;
  color: #160027;
  font-size: 24px;
  font-weight: 600;
}

.dynamic-chart {
  margin-bottom: 40px;
}

.wb-report-3-chart-1 {
  margin-bottom: 0;
}

.productStatistic {
  margin-bottom: 50px;
}
</style>
