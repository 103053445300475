<template>
  <div class="h-100 default-layout d-flex flex-column fix-width">
    <b-navbar
      v-if="$route.path !== '' || $route.path !== '/landing'"
      class="wb-header p-0 dashboard__navbar"
    >
      <b-container fluid class="px-5 h-100">
        <b-navbar-brand href="/" class="p-0">
          <b-img src="/img/logo.svg" width="121" height="40"></b-img>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav class="pl-4 h-100">
          <b-navbar-nav class="h-100">
            <b-nav-item :to="{ name: 'dashboard' }"> Дашборд</b-nav-item>
            <b-nav-item :to="{ name: 'organizations' }"
              >Организации
            </b-nav-item>

            <b-nav-item-dropdown right no-caret class="reports-dropdown">
              <template #button-content>
                Отчеты
                <img
                  src="/img/chevron-down.svg"
                  class="ml-2 wb-dropdown-icon"
                />
              </template>
              <b-dropdown-item
                :class="[
                  current_report === 'productsAdvertising' ? 'active-item' : '',
                ]"
                @click="goToReport('productsAdvertising')"
                >1. Все товары в рекламе
              </b-dropdown-item>
              <b-dropdown-item
                :class="[
                  current_report === 'CompaniesAdvertising'
                    ? 'active-item'
                    : '',
                ]"
                @click="goToReport('CompaniesAdvertising')"
                >2. Все рекламные кампании
              </b-dropdown-item>
              <b-dropdown-item
                :class="[current_report === 'dyn_orders' ? 'active-item' : '']"
                @click="goToReport('dyn_orders')"
                >5. Отчёт РнП
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto h-100">
            <b-nav-item-dropdown right no-caret class="nav_item_dropdown">
              <template #button-content>
                <span
                  class="truncate-text"
                  v-if="users[0] && currentOrganization"
                  >{{ currentOrganization.fullname }}</span
                >
                <span class="truncate-text" v-else>Ваши организации</span>
                <img
                  src="/img/chevron-down.svg"
                  class="ml-3 wb-dropdown-icon"
                />
              </template>
              <b-dropdown-item
                @click="selectUser(user)"
                v-for="user of users"
                :key="user.id"
                href="#"
                >{{ user.fullname }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right no-caret class="user-actions">
              <template #button-content>
                <b-img src="/img/hat.png" width="48" height="48" />
                <img
                  src="/img/chevron-down.svg"
                  class="ml-3 wb-dropdown-icon"
                />
              </template>
              <b-dropdown-item>
                <router-link to="/profile" class="link_profile">
                  Профиль
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item class="logout" href="#" @click="logout"
                >Выйти
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>

    <main class="wb-main flex-grow-1 d-flex flex-column">
      <b-container fluid class="px-4 d-flex flex-column flex-grow-1">
        <div class="flex-grow-1" id="content">
          <b-container fluid>
            <router-view />
          </b-container>
        </div>

        <hr class="wb-footer-hr" id="end-page" />

        <footer class="wb-footer d-flex">
          <div class="flex-grow-1">
            Есть вопросы? Нашли баг? Нужна доработка? — Пишите в telegram
            <a href="https://t.me/+CMS7NzvwMtIxMWZi" target="_blank"
              >wbleads
              <b-icon-telegram />
            </a>
          </div>

          <div class="copyright">
            WBLeads | {{ new Date().getFullYear() }} &copy;
          </div>
        </footer>
      </b-container>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'DefaultLayout',
  data() {
    return {
      searchValue: '',
      from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      current_report: '',
      isLoadingUsers: true,
    };
  },
  methods: {
    ...mapMutations(['SET_CURRENT_ORGANIZATION']),

    ...mapActions(['getUsers', 'getProductStatistic', 'getProfileInfo']),
    ...mapMutations('auth', ['LOGOUT']),

    goToReport(name) {
      this.current_report = name;
      switch (name) {
        case 'productsStatistic':
          this.$router.push('/reports/products-statistic');
          break;

        case 'dyn_orders':
          this.$router.push('/reports/dyn-products');
          break;

        default:
          this.$router.push({ name: name });
      }
    },

    clearAllStorage() {
      const listStores = [
        'WBLeadFilter-products-advertising',
        'WBLeadColumnproductsAdvertising',
        'WBLeadColumnSortproductsAdvertising',
        'WBLeadFilter-companies-advertising',
      ];

      for (let store of listStores) {
        localStorage.removeItem(store);
      }
    },

    selectUser(user) {
      this.clearAllStorage();
      localStorage.setItem('organization', JSON.stringify(user));
      window.location.reload();
    },
    logout() {
      this.LOGOUT();
      this.$router.push('auth/login');
    },
    async searchHandler() {
      let params = {
        account_id: 1,
        start_date: this.from,
        end_date: this.to,
        nm_id: this.searchValue,
      };
      if (!this.$route.query.id) {
        await this.getProductStatistic(params);
        this.$router.push({
          path: `/reports/products-statistic/${this.searchValue}`,
          query: { id: this.searchValue },
        });
        this.$forceUpdate();
      } else {
        this.$router.push({
          path: `/reports/products-statistic/${this.searchValue}`,
          query: { id: this.searchValue },
        });
      }
    },
  },
  watch: {
    '$route.path'() {
      if (!this.currentOrganization.tokens_state.wb_api_token) {
        this.$notify({
          type: 'error',
          duration: 10000,
          text: 'API токен этого кабинета истёк. Обновите его, пожалуйста. <a href="https://wbleads.ru/organizations">Обновить</a>',
        });
      }
    },
    currentUserTariffInfo(currentUserTariffInfo) {
      if (
        currentUserTariffInfo &&
        !currentUserTariffInfo.has_active_subscription
      ) {
        this.$notify({
          type: 'error',
          duration: 10000,
          text: 'Ваша подписка истекла. Продлите её пожалуйста, во вкладке Тарифы. <a href="https://wbleads.ru/tariffs">Продлить</a>',
        });
      }
    },
  },
  computed: {
    ...mapGetters([
      'users',
      'currentUser',
      'currentUserTariffInfo',
      'currentOrganization',
    ]),
    ...mapGetters('auth', ['isAuthenticated']),
  },
  async mounted() {
    if (this.isAuthenticated) {
      const usersArr = await this.getUsers({ options: { isStart: true } });
      await this.getProfileInfo();

      if (this.currentOrganization.id === 1 && usersArr[0]) {
        this.selectUser(usersArr[0]);
      }
    }
    this.isLoadingUsers = false;
  },
};
</script>

<style scoped>
.input-search {
    background: white !important;
    border: 1px solid #f1f1f1;
    height: 42px;
    width: 240px;
    border-radius: 10px;
}

.input-container {
    position: relative;
}

.fix-width {
    max-width: 100vw;
    box-sizing: border-box;
    overflow: hidden;
}

.input-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the left value to position the icon as desired */
    transform: translateY(-50%);
    color: #999; /* You can change the color of the icon to match the input placeholder */
}

.active-item {
    border-bottom: 2px solid #481173;
}
</style>
<style lang="scss">
.dropdown-item:active {
  background-color: white !important;
  color: #481173 !important;
}

.truncate-text {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav_item_dropdown .dropdown-menu {
  min-height: 50px;
  max-height: 300px;
  overflow-y: auto;
}

.link_profile {
  color: #160027 !important;
  border-bottom: none !important;
}

.notification-content > a {
  color: #42aaff !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: underline !important;
  }
}
</style>
