<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['saleProcent'],
  data() {
    return {
      promo: null,
      status: 'default',
      message: '',
      showDeleteButton: false,
    };
  },
  computed: {
    ...mapGetters(['currentUserTariffInfo']),
  },
  methods: {
    ...mapActions(['getProfileInfo']),
    handleUpdatePromo() {
      if (this.status !== 'pending') {
        this.status = 'pending';
        axios
          .post('/promocode/activate/', { promocode: this.promo })
          .then((response) => {
            this.status = 'success';
            this.showDeleteButton = true;
            this.$emit('getTariffs');
          })
          .catch((e) => {
            this.status = 'error';
            this.message = e.response.data.detail;
          });
      }
    },
    deleteActivePromo() {
      if (this.status !== 'pending') {
        this.status = 'pending';
        axios
          .post('/promocode/deactivate/')
          .then(() => {
            this.status = 'default';
            this.promo = '';

            this.showDeleteButton = false;
            this.$emit('getTariffs');
            this.message = '';
          })
          .catch((e) => {
            this.status = 'error';
            this.$notify({
              type: 'error',
              duration: 10000,
              text: 'Не удалось удалить промокод. Попробуйте еще раз',
            });
          });
      }
    },
  },
  watch: {
    saleProcent: {
      handler(saleProcent) {
        if (saleProcent) {
          this.message = `Промокод ${this.promo} на скидку ${saleProcent}% применен`;
        }
      },
    },
  },
  mounted() {
    this.promo = this.currentUserTariffInfo.promocode;
    this.showDeleteButton = !!this.currentUserTariffInfo.promocode;
    this.status = !!this.currentUserTariffInfo.promocode
      ? 'success'
      : this.status;
    this.message = this.saleProcent
      ? `Промокод ${this.promo} на скидку ${this.saleProcent}% применен`
      : '';
  },
};
</script>

<template>
  <div class="promo">
    <h5 class="promo__title">Промокод</h5>
    <div class="promo__control">
      <b-form-input placeholder="Введите ваш промокод" v-model="promo" />
      <a
        v-if="!showDeleteButton"
        @click="handleUpdatePromo"
        class="promo__accept link_as_btn"
      >
        Применить
      </a>
      <a v-else @click="deleteActivePromo" class="promo__accept link_as_btn">
        Удалить
      </a>
    </div>
    <div v-if="message" :class="`promo__status ${status}`">
      {{ message }}
    </div>
  </div>
</template>

<style scoped lang="scss">
$grey: #908f90;

.promo {
  padding: 36px 1rem;
  background: #fff;

  &__title {
    color: $grey;
    font-size: 16px;
    font-weight: 400;
  }

  &__control {
    position: relative;
    width: 460px;
  }

  &__input {
    height: auto;
    padding: 12px 16px;
    border: none;
    border-bottom: 1px solid #ece6f0;
    color: $grey;
    font-size: 18px;
    font-weight: 400;
    outline: none;
    box-shadow: none !important;
    background: #fff !important;
  }
  &__status {
    margin-top: 5px;
    &.error {
      color: #f44336;
    }
    &.success {
      color: #459a48;
    }
  }

  &__accept {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    color: $grey;

    font-size: 16px;
    font-weight: 600;
  }
}
</style>
