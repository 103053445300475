<template>
  <div class="auth-card">
    <div class="title mb-4">Вход</div>

    <div class="invalid_pass" v-if="isShowMessagePass">
      Не правильный логин/пароль
    </div>

    <validation-observer ref="observer">
      <b-form onsubmit="onSubmitLogin">
        <validation-provider
          name="Email"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group class="mb-3">
            <b-form-input
              v-model="email"
              placeholder="Email"
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Пароль"
          :rules="{ required: true, min: 8 }"
          v-slot="validationContext"
          class="input_pass"
        >
          <password-field
            v-model="password"
            class="mb-3"
            :state="getValidationState(validationContext)"
            :errors="validationContext.errors"
          />
        </validation-provider>

        <div class="d-flex">
          <b-form-checkbox class="w-50 remember-me d-flex align-items-center"
            >Запомнить меня</b-form-checkbox
          >

          <div class="w-50 text-right">
            <a
              @click.prevent="$router.push({ name: 'auth.forget' })"
              href="/auth/forget"
              class="forget-password"
              >Забыли пароль?</a
            >
          </div>
        </div>

        <b-btn
          @click.prevent="handleLogin"
          :disabled="processing"
          pill
          class="mt-4 w-100 py-2"
          variant="wblead"
          >Войти</b-btn
        >
      </b-form>
    </validation-observer>

    <div class="no-account mt-5 text-center">
      Нет аккаунта?
      <a
        @click.prevent="$router.push({ name: 'auth.register' })"
        href="/auth/register"
        >Зарегистрироваться</a
      >
    </div>
  </div>
</template>

<script>
import PasswordField from '@/components/ui/PasswordField.vue';
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  components: { PasswordField },
  data() {
    return {
      processing: false,
      email: null,
      password: null,
      isShowMessagePass: false,
    };
  },
  async beforeMount() {
    const logInAsToken = this.$route.query.token;
    if (logInAsToken) {
      await this.loginAsAdmin({ token: logInAsToken });
    }
  },
  methods: {
    ...mapActions('auth', ['login', 'loginAsAdmin']),

    onSubmitLogin(event) {
      event.preventDefault();
    },

    async handleLogin(event) {
      this.isShowMessagePass = false;

      event.preventDefault();
      if (await this.$refs.observer.validate()) {
        this.processing = true;

        const status = await this.login({
          email: this.email,
          password: this.password,
        });

        switch (status) {
          case 'redirect':
            this.$router.push({
              name: 'auth.otp',
              query: {
                email: this.email,
              },
            });
            break;

          case 'success':
            location.reload();
            break;

          case 'invalid-pass':
            this.isShowMessagePass = true;
            break;

          default:
            throw status;
        }

        this.processing = false;
      }
    },
  },
};
</script>

<style lang="scss">
.invalid_pass {
  text-align: center;
  color: red;
  margin: 1rem 0;
}
</style>
