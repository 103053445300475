import { render, staticRenderFns } from "./CompanyLimitEmpty.vue?vue&type=template&id=dc7c4be6&scoped=true"
import script from "./CompanyLimitEmpty.vue?vue&type=script&setup=true&lang=js"
export * from "./CompanyLimitEmpty.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CompanyLimitEmpty.vue?vue&type=style&index=0&id=dc7c4be6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc7c4be6",
  null
  
)

export default component.exports