<template>
  <b-modal ref="settingsModal" @hide="close" centered title="Настройка колонок">
    <nav class="modal_settings__tabs">
      <a
        class="tab"
        :class="{ active: view === 'general' }"
        @click="changeView('general')"
        >Общие</a
      >
      <a
        class="tab"
        :class="{ active: view === 'table' }"
        @click="changeView('table')"
        >Таблица</a
      >
    </nav>

    <div class="modal_settings__content">
      <div v-if="view === 'table'">
        <ul class="modal_settings__list" v-if="columnsSettings">
          <li
            v-for="column in columnsSettings"
            :key="column.field"
            class="js-sortable__item"
          >
            <b-form-checkbox
              :disabled="column.disableCheckbox"
              v-model="column.checked"
              variant="wblead"
              unchecked-value="false"
            >
              <span class="modal_settings__text">{{ column.headerName }}</span>
            </b-form-checkbox>
          </li>
        </ul>
      </div>

      <div class="modal_settings__general" v-if="view === 'general'">
        <div>
          <b-form-checkbox
            id="CheckboxShowNull"
            v-model="isShowNull"
            variant="wblead"
            unchecked-value="false"
          >
            <span class="modal_settings__text">
              {{ renderLabel() }}
            </span>
          </b-form-checkbox>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="modal_settings__footer">
        <b-btn variant="default" @click="removeSettings"
          >сбросить все фильтры</b-btn
        >

        <div class="control_right">
          <b-btn variant="default" @click="close">Отмена</b-btn>
          <b-btn class="wb-btn" @click="save">Сохранить</b-btn>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'NewModalSettings',
  props: ['columns', 'generalOptions', 'showZeros', 'pageName'],
  data() {
    return {
      view: 'table',
      isShowNull: true,
      columnsSettings: null,
    };
  },
  methods: {
    changeView(view) {
      this.view = view;
    },
    renderLabel() {
      switch (this.pageName) {
        case 'productsAdvertising':
        case 'report-5':
          return 'Скрыть товары с нулями';

        default:
          return 'Скрыть кампании с нулями';
      }
    },

    removeSettings() {
      this.isShowNull = true;
      this.$emit('setShowZeros', true);
      this.columnsSettings = this.getColumnsSettings();
      localStorage.setItem(
        `${this.pageName}-table-settings`,
        JSON.stringify(this.columnsSettings)
      );
      this.$emit('setColumns', this.columns);
      this.close();
    },

    close() {
      this.$refs.settingsModal.hide();
    },

    open() {
      this.$refs.settingsModal.show();
    },

    save() {
      const isShowNull = this.isShowNull === true;
      if (!isShowNull !== this.showZeros) {
        this.$emit('setShowZeros', !isShowNull);
      }
      localStorage.setItem(
        `${this.pageName}-table-settings`,
        JSON.stringify(this.columnsSettings)
      );
      this.$emit('setColumns', this.setColumnSettings(this.columns));
      this.close();
    },

    setColumnSettings(columns) {
      return columns.reduce((acc, column) => {
        if (column.children) {
          const columnChildren = this.setColumnSettings(column.children);
          return [...acc, { ...column, children: columnChildren }];
        }
        const columnSettings = this.columnsSettings.find(
          (el) => el.name === column.name
        );
        return columnSettings.checked === true ? [...acc, column] : acc;
      }, []);
    },

    getColumnsSettings() {
      const groupNames = [];
      const filterNames = [];
      return this.columns
        .filter((column) => {
          if (!column.groupName) {
            return true;
          }
          if (groupNames.includes(column.groupName)) {
            return false;
          }
          groupNames.push(column.groupName);
          return true;
        })
        .flatMap((column) => (column.children ? column.children : [column]))
        .filter((column) => {
          if (filterNames.includes(column.name)) {
            return false;
          }
          filterNames.push(column.name);
          return true;
        })
        .map((column) => ({ ...column, checked: true }));
    },
  },
  mounted() {
    this.isShowNull = !this.showZeros ?? false;
    this.columnsSettings =
      JSON.parse(localStorage.getItem(`${this.pageName}-table-settings`)) ??
      this.getColumnsSettings();
    setTimeout(() => {
      this.$emit('setColumns', this.setColumnSettings(this.columns));
    }, 0);
  },
};
</script>
<style lang="scss">
.modal_settings__list {
  padding-left: 0;
  list-style: none;
}

.modal_settings__text {
  position: relative;
  top: -3px;
  font-size: 20px;
  cursor: pointer;
}

.modal_settings__content {
  padding-top: 1.5rem;
}

.modal_settings__tabs {
  & .tab {
    padding: 0.5rem 1rem;
    border: 1px solid #481173;
    color: #481173;
    text-decoration: none;
    cursor: pointer;
    background: none;

    &.active {
      color: #fff;
      background: #481173;
    }
  }
}

.modal-footer {
  display: block;

  & .modal_settings__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .control_right {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}
</style>
