<script>
export default {
  props: ['tariff'],
};
</script>

<template>
  <div
    class="landing_tariff"
    :class="{
      'landing_tariff-white': tariff.color === 'white',
    }"
  >
    <div class="landing_tariff__header">
      <h6 class="landing_tariff__name">
        {{ tariff.name }}
      </h6>
      <span class="landing_tariff__price">
        {{ tariff.price }}
      </span>
    </div>

    <div class="landing_tariff__discount">
      <span>/{{ tariff.discount }}</span>
    </div>

    <div class="landing_tariff__benefits">
      <h6 class="landing_tariff__benefits__title">План включает:</h6>
      <ul class="landing_tariff__benefits__list">
        <li v-for="benefit in tariff.benefits" :key="benefit">
          <img
            src="@/assets/img/landing-white-plus-icon.svg"
            width="16"
            height="16"
            fill="white"
          />
          <span class="landing_tariff__benefits__list__name">
            {{ benefit }}
          </span>
        </li>
      </ul>
    </div>

    <div class="landing_tariff__wrap_get">
      <a class="landing_tariff__get"> Попробовать </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_tariff {
  width: 312px;
  height: 480px;
  padding: 28px 24px;
  box-shadow: 0 0 36px 0 rgba(47, 10, 73, 0.1);
  border-radius: 14px;
  background: #481173;
  position: relative;

  &.last {
    & .landing_tariff__wrap_get {
      padding-top: 40px;
    }
  }

  &__header {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-size: 24px;
  }

  & .landing_tariff__name,
  & .landing_tariff__price {
    color: #fff;
  }

  &__discount {
    position: relative;
    top: -11px;
    color: #908f90;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
  }

  &__benefits {
    padding: 1rem 0 44px;

    &__title {
      margin-bottom: 24px;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
    }

    &__list {
      display: flex;
      flex-direction: column;
      list-style: none;
      gap: 1rem;
      padding: 0;

      &__name {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  &__wrap_get {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__get {
    height: 48px;
    padding: 12px 22px;
    display: inline-block;
    border-radius: 1000px;
    color: #160027;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    background: #fff;
  }
  &__benefits__list__icon {
    svg {
      fill: white;
    }
  }
  @media screen and (max-width: 570px) {
    &__name {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.landing_tariff.landing_tariff-white {
  background: #fff;

  & .landing_tariff__name,
  & .landing_tariff__price {
    color: #160027;
  }

  & .landing_tariff__benefits__title {
    color: #160027;
  }

  & .landing_tariff__benefits__list__name {
    color: #160027;
  }

  & .landing_tariff__get {
    color: #fff;
    background: #481173;
  }
}
</style>
