<script>
export default {
  name: 'LandingVideo',

  data() {
    return {
      isHide: false,
      playerVars: {
        autoplay: 1,
      },
      videoWidth: 926,
      videoHeight: 450,
    };
  },
  methods: {
    handleClickPreview() {
      this.isHide = true;
    },
  },
  mounted() {
    const width = window.innerWidth * 0.9;
    const height = (width * this.videoHeight) / this.videoWidth;
    this.videoWidth = width;
    this.videoHeight = height;
  },
};
</script>

<template>
  <div class="landing_video">
    <h5 class="landing_video__title">Обзор сервиса WBLeads</h5>

    <p class="landing_video__description">
      Посмотрите видео и узнайте, как пользоваться сервисом
    </p>

    <div
      class="landing_video__file"
      :style="{
        height: videoHeight + 'px',
        width: videoWidth + 'px',
      }"
    >
      <iframe
        v-if="isHide"
        src="https://vk.com/video_ext.php?oid=-206470947&id=456239256&hd=2&autoplay=1"
        :width="videoWidth"
        :height="videoHeight"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        frameborder="0"
        allowfullscreen
      ></iframe>

      <div
        class="landing_video__preview"
        :class="{ hide: isHide }"
        @click="handleClickPreview"
      >
        <img src="/img/youtube-video-wrap.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_video {
  text-align: center;

  &__title,
  &__description {
    color: #160027;
  }

  &__file {
    position: relative;
    margin: 0 auto;
  }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &.hide {
      display: none;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-size: 50px;
    font-weight: 500;
  }

  &__description {
    margin-bottom: 40px;
    font-size: 18px;
  }
  @media screen and (max-width: 570px) {
    &__title {
      margin: 40px 0px;
      font-size: 32px;
    }
    &__file {
      position: relative;
      margin: 0 auto;
    }
  }
}
</style>
