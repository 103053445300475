<script setup>
import LandingFooterNav from '@/components/landing/LandingFooterNav.vue';
import LandingHostInfo from '@/components/landing/LandingHostInfo.vue';
import { mobileSize } from '@/constants/mobile-size';

const nav = [
  {
    title: 'Навигация',
    links: [
      { path: '#About', name: 'О сервисе' },
      { path: '#Opportunities', name: 'Возможности' },
      { path: '#Advantages', name: 'Преимущества' },
      { path: '#Review', name: 'Обзор' },
      { path: '#Faq', name: 'FAQ' },
      { path: 'https://t.me/wbleads_cases', name: 'Отзывы' },
    ],
  },
  {
    title: 'Прочее',
    links: [
      { path: '/x1', name: 'Telegram' },
      { path: '/x2', name: 'Instagram' },
      { path: '/x3', name: 'VK' },
    ],
  },
];
const payments = [
  { path: 'x4', img: '/img/spb.svg' },
  { path: 'x5', img: '/img/landing-tinkoff.svg' },
];
const isMobile = window.innerWidth < mobileSize;
</script>

<template>
  <div class="landing_footer_right">
    <div class="landing_footer_right__wrap_nav">
      <LandingFooterNav
        v-for="navItem in nav"
        :key="navItem.title"
        :navItem="navItem"
      />
    </div>

    <LandingHostInfo v-if="isMobile" />
    <p class="landing_footer_right__description">Принимаем к оплате</p>

    <div class="landing_footer_right__payments">
      <a v-for="payment in payments" :key="payment.path">
        <img :src="payment.img" alt="Img of payment" />
      </a>
    </div>

    <p class="payments_description">
      *Все цены указанные на сайте носят исключительно<br />
      информативный характер и не являются публичной<br />
      офертой, определяемой ст.437 ГК
    </p>
  </div>
</template>

<style scoped lang="scss">
.landing_footer_right {
  &__wrap_nav {
    display: flex;
    gap: 80px;
    align-items: start;
    justify-content: end;
  }

  &__payments {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: end;
  }

  &__description {
    margin-top: 50px;
    margin-bottom: 4px;
    color: #908f90;
    font-size: 14px;
    text-align: right;
  }

  & .payments_description {
    margin-top: 4px;
    color: #908f90;
    font-size: 14px;
    text-align: right;
  }

  @media screen and (max-width: 570px) {
    &__wrap_nav {
      justify-content: flex-start;
    }
    &__payments {
      justify-content: flex-start;
    }
    &__description,
    & .payments_description {
      text-align: left;
    }
    &__description {
      margin-top: 30px;
    }
  }
}
</style>
