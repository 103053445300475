<script setup>
const renderYear = () => {
  const date = new Date();
  return date.getFullYear();
};

const links = [
  { path: '/agreement', name: 'Пользовательское соглашение' },
  { path: '/confidential', name: 'Политика конфиденциальности' },
  { path: '/payment-offer', name: 'Оферта регулярных платежей' },
];
</script>

<template>
  <div class="landing_footer_bottom">
    <div class="landing_footer_bottom__year-block">
      {{ renderYear() }} © WBLeads
    </div>

    <div class="landing_footer_bottom__links">
      <router-link
        v-for="link in links"
        :to="link.path"
        class="landing_footer_bottom__link"
      >
        {{ link.name }}
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_footer_bottom {
  padding: 20px 0;
  border-top: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #908f90;
  font-size: 14px;

  &__links {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: end;
  }

  &__link {
    color: #908f90;
    text-decoration: none;

    &:hover,
    &:active {
      color: #481173;
    }
  }
  @media screen and (max-width: 570px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    &__links {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      gap: 5px;
    }
    &__year-block {
      margin-top: 20px;
    }
  }
}
</style>
