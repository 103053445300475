<script setup>
import { ref } from 'vue';

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
});

const isOpen = ref(props.question.isShowDefault || false);

const toggleView = () => (isOpen.value = !isOpen.value);
</script>

<template>
  <section class="landing_question">
    <div class="landing_question__header">
      <a class="landing_question__title" @click="toggleView">{{
        props.question.title
      }}</a>
      <a class="landing_question__header__close" @click="toggleView">
        <img src="/img/landing-btn-close.svg" alt="Close" v-if="isOpen" />
        <img src="/img/landing-btn-show.svg" alt="Show" v-if="!isOpen" />
      </a>
    </div>

    <p class="landing_question__text" v-if="isOpen">
      {{ props.question.text }}
    </p>
  </section>
</template>

<style scoped lang="scss">
$borderColor: #F1F1F1;

.landing_question {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 0 30px;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;

  &__title {
    color: #160027;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:active {
      color: #771CBD;
    }
  }

  &__header {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: auto 24px;
    gap: 1rem;
  }

  &__text {
    color: #908F90;
    font-size: 16px;
    line-height: 130%;
  }
}
</style>
