<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';

import ProfileOffer from '@/components/profile/ProfileOffer.vue';
import WBLoader from '@/components/loader/WBLoader.vue';

export default {
  name: 'ViewUnsubscribe',

  computed: {
    ...mapGetters(['currentUserTariffInfo']),
  },

  components: {
    ProfileOffer,
    WBLoader,
  },

  data() {
    return {
      type: 'default',
    };
  },

  methods: {
    renderDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },

    unsubscribe() {
      let url = '/subscription/stop/';

      if (this.type === 'recovery') {
        url = '/subscription/activate/';
      }

      axios({
        url: url,
        method: 'POST',
      })
        .then(() => {
          this.$notify({
            type: 'success',
            title: `Успешно. Подписка ${
              this.type === 'recovery' ? 'активирована' : 'отменена'
            }`,
          });

          setTimeout(() => {
            this.$router.push('/tariffs');
          }, 1500);
        })
        .catch((err) => {
          console.error(err);

          this.$notify({
            type: 'error',
            title: 'Ошибка',
          });
        });
    },

    setTypePage() {
      const searchParam = location.search;
      console.log('searchParam', searchParam);
      if (searchParam === '?recovery') {
        this.type = 'recovery';
      }
    },
  },

  mounted() {
    this.setTypePage();
  },
};
</script>

<template>
  <div class="view_unsubscribe_tariff">
    <h4 class="view_unsubscribe_tariff__title">
      {{ type === 'recovery' ? 'Восстановление' : 'Отмена' }} подписки
    </h4>

    <p v-if="type === 'recovery'" class="recovery-text">
      При восстановлении подписки вы продолжите пользоваться текущей до
      {{ renderDate(currentUserTariffInfo.subscription_payed_until) }}, а в
      конце расчетного периода с вас будет списан регулярный платёж.
    </p>

    <WBLoader v-if="!currentUserTariffInfo.id" />
    <div v-if="currentUserTariffInfo.id">
      <div class="view_unsubscribe_tariff__current_tariff">
        <ProfileOffer
          :isShowHoverEffect="false"
          :tariff="currentUserTariffInfo.current_subscription.tariff"
          :is-my="true"
        />
      </div>

      <p
        class="view_unsubscribe_tariff__description"
        v-if="type !== 'recovery'"
      >
        Ваша подписка будет отменена, но вы сохраните доступ к платным сервисам
        до конца расчётного периода
        {{ renderDate(currentUserTariffInfo.subscription_payed_until) }}. Если
        вы передумаете, вы можете возобновить вашу подписку.
      </p>

      <p class="view_unsubscribe_tariff__offer">
        {{ type === 'recovery' ? 'Восстанавливая' : 'Отменяя' }} подписку вы
        соглашаетесь с
        <router-link to="/payment-offer">Офертой</router-link> регулярных
        платежей и
        <router-link to="/confidential"
          >Политикой конфиденцильности</router-link
        >
      </p>

      <div class="view_unsubscribe_tariff__control">
        <router-link
          to="/tariffs"
          class="link_as_btn view_unsubscribe_tariff__back"
        >
          Вернуться назад
        </router-link>
        <b-button class="wb-btn" @click="unsubscribe">
          {{ type === 'recovery' ? 'Восстановить' : 'Отменить' }} подписку
        </b-button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
$grey: #908F90;

.view_unsubscribe_tariff {
  padding: 57px 0;
  height: 89vh;

  &__title {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 500;
  }

  &__current_tariff__title {
    margin-bottom: 1rem;
    color: $grey;
    font-size: 16px;
  }

  &__description {
    margin: 40px 0;
    color: $grey;
    font-size: 16px;
  }

  &__offer {
    margin-bottom: 36px;
    color: $grey;
    font-size: 14px;
  }

  &__control {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  &__back {
    padding: 10px 48px;
    border-radius: 8px;
    border: 1px solid $grey;
    color: $grey;
    font-size: 16px;
    font-weight: 500;

    &:hover, &:active {
      color: #fff;
      background: #481173;
    }
  }
}

.recovery-text {
  margin-bottom: 2rem;
}
</style>
