import { render, staticRenderFns } from "./LandingVideo.vue?vue&type=template&id=8ccca05a&scoped=true"
import script from "./LandingVideo.vue?vue&type=script&lang=js"
export * from "./LandingVideo.vue?vue&type=script&lang=js"
import style0 from "./LandingVideo.vue?vue&type=style&index=0&id=8ccca05a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ccca05a",
  null
  
)

export default component.exports