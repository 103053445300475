import { mapObjectToFixed } from '@/helpers/mapObjectToFIxed';

export const mapItems = (items) => {
  return items.map((item) => ({
    ...mapObjectToFixed({
      ...item,
      sales_report: {
        ...item.sales_report,
        cpo: item.total.cpo,
        cpl: item.total.cpl,
      },
      total: {
        ...item.total,
        drr: item.sales_report.advert_drr,
        total_ordered_in_rubles_drr:
          item.sales_report.total_ordered_in_rubles_drr,
      },
    }),
  }));
};

export const mapTotals = (totals) => {
  return mapObjectToFixed({
    ...totals,
    sales_report: {
      ...totals.sales_report,
      cpo: totals.total.cpo,
      cpl: totals.total.cpl,
    },
    total: {
      ...totals.total,
      drr: totals.sales_report.advert_drr,
      total_ordered_in_rubles_drr:
        totals.sales_report.total_ordered_in_rubles_drr,
    },
  });
};
