import axios from 'axios';
import { mapItems, mapProducts, mapTotals } from './mapper';
import { NOT_SUITABLE_TARIFF } from '@/constants/error-responses';
import moment from 'moment';

const defaultOptions = {
  group_by: 'days',
  order_by: {
    order_type: 'DESC',
    order_field: 'total_in_pieces',
  },
  start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
};

export const actions = {
  getData({ commit, state }) {
    commit('PRODUCTS_ADVERTISING_SET_LOADING', true);
    const { group_by, order_by, ...options } = state.options;
    axios({
      url: '/new-reports/comm_art/',
      method: 'POST',
      data: {
        ...options,
        product_brand: [],
        product_sa_name: [],
        product_subject: [],
      },
      config: {
        paramsSerializer: {
          indexes: null,
        },
      },
    })
      .then((response) => {
        commit('PRODUCTS_ADVERTISING_SET_ITEMS', mapItems(response.data.data));
        commit(
          'PRODUCTS_ADVERTISING_SET_TOTAL_ITEMS',
          mapTotals(response.data.totals)
        );
      })
      .catch((err) => {
        console.error(err);
        commit('PRODUCTS_ADVERTISING_TO_INITIAL_VALUES');
        commit(
          'SET_SUITABLE_SUBSCRIPTION',
          err.response.data.detail !== NOT_SUITABLE_TARIFF
        );
      })
      .finally(() => {
        commit('PRODUCTS_ADVERTISING_SET_LOADING', false);
      });
  },

  setDefaultOptions({ commit }, id) {
    const storageOptions = localStorage.getItem('productsAdvertising-options');
    const options = storageOptions
      ? JSON.parse(storageOptions)
      : defaultOptions;
    commit('SET_OPTIONS', {
      ...options,
      wb_account: id,
      end_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    });
  },

  resetOptions({ commit, state }) {
    const data = { ...state.options, ...defaultOptions };
    commit('SET_OPTIONS', data);
  },

  setOptions({ commit, state }, options) {
    const data = { ...state.options, ...options };
    const { start_date, end_date, wb_account, ...localStorageData } = data;
    localStorage.setItem(
      'productsAdvertising-options',
      JSON.stringify(localStorageData)
    );
    localStorage.removeItem('productsAdvertising-filters');
    localStorage.removeItem('productsAdvertising-sort');
    commit('SET_OPTIONS', data);
  },

  setFilters({ commit }, filters) {
    localStorage.setItem(
      `productsAdvertising-filters`,
      JSON.stringify(filters)
    );
    commit('SET_FILTERS', filters);
  },
};
