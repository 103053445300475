<script setup>
import CompanyLimitEmpty from '@/components/dashboard/CompanyLimitEmpty.vue';

const companies = [];
</script>

<template>
  <aside class="dashboard_aside">
    <div class="dashboard_aside__title">
      <span>
        <img src="/img/icon-dashboard-noti.svg" alt="" class="icon_noti" />
      </span>
      <p class="text">
        Кампании, на которых закончится лимит в ближайшие 2 часа
      </p>
    </div>

    <nav class="dashboard_aside__list" v-if="companies[0]">
      <a v-for="company in companies" :key="company.id" :href="company.href">
        {{ company.name }}
      </a>
    </nav>
    <CompanyLimitEmpty />
  </aside>
</template>

<style lang="scss" scoped>
.dashboard_aside {
  margin-bottom: 17px;

  &__title {
    margin-bottom: 17px;
    display: grid;
    grid-template-columns: 32px calc(100% - 32px);
    gap: 12px;

    & .text {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }
  }

  &__list {
    padding: 16px 28px 16px 28px;
    border-radius: 10px;
    background: #FFF;

    & a {
      margin-bottom: 32px;
      display: inline-block;
      color: #481173;
      font-size: 17px;
      font-weight: 600;
      text-decoration-line: underline;

      &:hover, &:active {
        text-decoration: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
