<script setup>
import LandingNeedCard from '@/components/landing/LandingNeedCard.vue';

const items = [
  {
    id: 1,
    img: '/img/landing-need-icons/icon-computer.svg',
    text: 'Вся необходимая статистика рекламного кабинета собрана в 4 детальных отчётах.',
  },
  {
    id: 2,
    img: '/img/landing-need-icons/icon-troubles.svg',
    text: 'Знание о проблеме - половина успеха в её разрешении. С WB Lead вы узнаёте о проблеме в момент её возникновения.',
  },
  {
    id: 3,
    img: '/img/landing-need-icons/icon-guard.svg',
    text: 'Прямая работа с официальным API Wildberries гарантирует безопасность ваших данных и защищает от блокировок',
  },
  {
    id: 4,
    img: '/img/landing-need-icons/icon-time.svg',
    text: 'Оптимизация времени затрачиваемого на сбор нужно информации с Wildberries для анализа',
  },
];
</script>

<template>
  <div class="landing_need">
    <div class="landing_need__left">
      <div class="landing_need__left__container">
        <h5 class="landing_need__title">
          Для чего нужен <br />
          <span class="landing_word_color">WBLeads</span>?
        </h5>
        <p class="landing_need__text">
          Увеличенная осведомлённость, приводит к увеличению ваших доходов.
        </p>
      </div>

      <img
        class="landing_need__left__img"
        src="/img/landing-need-img.svg"
        alt=""
      />
    </div>

    <div id="Review" class="landing_need__right">
      <LandingNeedCard v-for="item in items" :key="item.id" :item="item" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_need {
  &__right {
    margin-top: 40px;
  }
  @media screen and (min-width: 570px) {
    display: flex;
    justify-content: space-between;
    gap: 114px;
    &__right {
      display: grid;
      justify-content: end;
      grid-template-columns: 240px 240px;
      gap: 32px;
      margin-top: 0px;
    }
  }
  &__left {
    width: 286px;

    &__container {
      margin-bottom: 72px;
    }
  }

  &__title {
    margin-bottom: 28px;
    font-size: 32px;
    font-weight: 500;
  }

  &__text {
    font-size: 18px;
  }
}
</style>
