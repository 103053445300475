<template>
  <div style="overflow-x: auto" v-if="isShow">
    <table bordered class="mt-4 product-statistic-table_total_data mb-1">
      <thead>
        <tr>
          <th
            style="
              width: 160px;
              background: #fcf8ff !important;
              z-index: 10;
              position: sticky;
            "
            class="sticky-col text-left pl-2"
          >
            Дата
          </th>
          <th
            style="background: #fcf8ff !important"
            v-for="(col, i) in renderGroup('header', data)"
            :key="i"
          >
            {{ renderDay(col) }}
          </th>
        </tr>
        <tr>
          <th class="sticky-col" style="border: 1px solid transparent">
            <div v-if="isTotal">
              <p class="product-statistic-table_total_data__advert-name">
                Итог
              </p>
            </div>
            <a
              v-else
              class="product-statistic-table_total_data__advert-name"
              :href="`/reports/statistic-advertising/${data.campaign_id}`"
            >
              {{ data.campaign_name }} {{ data.campaign_id }}
            </a>
          </th>
          <th :colspan="3" class="th_limit">
            <span class="th_limit__text">{{ renderLimit }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in renderPros()" :key="index">
          <td class="sticky-col">
            <span class="product-statistic-table_total_data__item-label">
              {{ item.label }}
            </span>
          </td>
          <td v-for="(row, i) in renderGroup('body', data)" :key="i">
            <span class="product-statistic-table_total_data__item">
              <span v-if="item.key === 'bid'">
                {{ row.bid }}
              </span>
              <span v-else>
                <span v-if="['seller_price'].includes(item.key)">
                  {{ renderPrice(renderValue(item.key, row[item.key] / 100)) }}
                </span>
                <span v-else>
                  <span v-if="item.key === 'discount_price'">
                    {{ renderValue(null, row.discount_price) }}
                  </span>
                  <span v-if="item.key === 'cpc' || item.key === 'drr'">{{
                    renderValue(item.key, row[item.key])
                  }}</span>
                  <span
                    v-if="!['cpc', 'discount_price', 'drr'].includes(item.key)"
                  >
                    {{
                      row[item.key] === 'ctr_percent'
                        ? renderValue(item.key, row[item.key])
                        : renderPrice(renderValue(item.key, row[item.key]))
                    }}
                  </span>
                </span>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { renderTextFromItem } from '@/utils/text';
import { renderPrice } from '@/utils/prices';
import moment from 'moment';

export default {
  name: 'totalTable',
  props: {
    data: {},
    activeGroup: {
      type: String,
    },
    isTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      moment: moment,
      isShow: false,
      propsGeneral: [
        { label: 'Расход по РК, руб', key: 'consumption' },
        { label: 'CPM на WB, руб', key: 'bid' },
        // { label: "Рекламное место по кампании", key: "slot" },
        { label: 'CPM факт по кампании, руб', key: 'cpm_rub', text: 'руб' },
        { label: 'Просмотры, шт', key: 'views' },
        { label: 'Клики, шт', key: 'clicks' },
        { label: 'CTR %', key: 'ctr_percent' },
        { label: 'CPC, руб', key: 'cpc' },
        { label: 'CPL, руб', key: 'cost_cart_rub', text: 'руб' },
        { label: 'CPO, руб', key: 'cost_order_rub', text: 'руб' },
        {
          label: 'Добавление в корзину, шт',
          key: 'added_cart_pcs',
          text: 'руб',
        },
        {
          label: 'Оформление заказа по рекламе, шт',
          key: 'placing_order_pcs',
          text: 'руб',
        },
      ],
      propsTotals: [
        { label: 'Цена, руб', key: 'discount_price' },
        { label: 'Цена с СПП, руб', key: 'seller_price' },
        { label: 'Общий расход по всем РК, руб', key: 'consumption' },
        { label: 'Просмотры общ, шт', key: 'views' },
        { label: 'Клики общ, шт', key: 'clicks' },
        { label: 'CPM факт, руб', key: 'cpm_rub' },
        { label: 'Добавления в корзину, шт', key: 'added_cart_pcs' },
        { label: 'Заказы по РК, шт', key: 'placing_order_pcs' },
        { label: 'Заказы по отчету ВБ, шт', key: 'ordered_in_pieces' },
        {
          label: 'Заказы по отчету ВБ, руб',
          key: 'ordered_in_rubles',
          text: 'руб',
        },
        { label: 'Продажи по отчету ВБ, шт', key: 'redeemed' },
        { label: 'Продажи по отчету ВБ, руб', key: 'to_transfer', text: 'руб' },
        // { label: "Породажи фин. отчет, шт", key: "redeemed_pcs" },
        // { label: "Продажи фин. отчет, руб", key: "redeemed_rub", text: 'руб' },
        {
          label: 'CPL Стоимость корзины, руб (средн)',
          key: 'cost_cart_rub',
          text: 'руб',
        },
        {
          label: 'CPO Стоимость заказа, руб (средн)',
          key: 'cost_order_rub',
          text: 'руб',
        },
        // { label: "CPS Стоимость продажи, руб (средн)", key: "cps" },
        { label: 'TACoO от суммы заказов, %', key: 'drr' },
        { label: 'CTR средний по артикулу, %', key: 'ctr_percent' },
        { label: 'Остаток товара, шт', key: 'stock' },
      ],
    };
  },

  computed: {
    renderLimit() {
      if (typeof this.limit === 'number') {
        return `Лимита осталось меньше чем на ${this.limit} часа`;
      }

      return '';
    },
  },

  methods: {
    renderPrice,
    renderTextFromItem,

    renderPros() {
      if (!this.isTotal) {
        return this.propsGeneral;
      } else {
        return this.propsTotals;
      }
    },

    renderValue(key, value) {
      if (Number(value) === 0) return value;

      if (['cpm_rub'].includes(key)) {
        if (!isNaN(Number(value))) return Math.ceil(value.toFixed(1));
      }

      if (['drr', 'cpc'].includes(key)) {
        const ctrValue = value.toFixed(2);

        if (ctrValue.toString().length < 2) {
          return ctrValue.toString() + '0';
        }

        return Number(ctrValue).toFixed(2);
      }

      if (['ctr_percent'].includes(key)) {
        if (!isNaN(Number(value))) {
          return Number(value).toFixed(2);
        }
      }

      if (['cost_cart_rub'.includes(key)]) {
        try {
          if (!isNaN(Number(value))) {
            return Math.ceil(value.toFixed(2));
          }
        } catch (err) {
          return value;
        }
      }

      return value;
    },

    formatDate(dateString) {
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      const date = new Date(dateString);
      const dayOfWeek = daysOfWeek[date.getDay()];

      return `${dateString} ${dayOfWeek}`;
    },

    renderDay(item) {
      switch (this.activeGroup) {
        case 'days':
          return moment(item.day).format('DD.MM.YYYY');

        case 'weeks':
          const start = item.days[0];
          const end = item.days[item.days.length - 1];
          return `${start} - ${end}`;

        case 'months':
          const listMonths = [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
          ];
          const str = listMonths[item.month - 1];
          return `${str} ${item.year}`;

        case 'years':
          return item.year;

        default:
          return '';
      }
    },

    renderGroup(type, advert) {
      switch (this.activeGroup) {
        case 'days':
          if (this.isTotal) {
            return this.data.days;
          } else return advert.days;

        case 'weeks':
          if (this.isTotal) return this.data.weeks;
          else return advert.weeks;

        case 'months':
          if (this.isTotal) return this.data.months;
          else return advert.months;

        case 'years':
          if (this.isTotal) return this.data.years;
          else return advert.years;

        default:
          return [];
      }
    },
  },

  created() {
    this.isShow = true;
  },
};
</script>

<style lang="scss">
@import "../index.scss";
table.product-statistic-table_total_data {
  & thead {
    position: sticky;
    top: -1px;
    background-color: white;
    z-index: 2;

    & th {
      color: #908F90 !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      box-shadow: none !important;

      &:first-child {
        border-right: 1px solid #F1F1F1 !important;
      }

      &.th_limit {
        background: #fff !important;
      }
    }
  }

  & tbody {
    & tr {
      & td {
        background: #fafafa !important;
      }

      &:nth-child(odd) {
        & td {
          background: #fff !important;
        }
      }
    }

    & td {
      padding: 1px 2px !important;
      box-shadow: none !important;
      color: #160027 !important;
      font-size: 10px;

      &:first-child {
        border-right: 1px solid #F1F1F1 !important;
      }

      & span {
        box-shadow: none !important;
      }
    }
  }
}

.product-statistic-table_total_data__advert-name {
  padding-left: 8px;
  justify-content: start !important;
}

.th_limit {
  border-right: none !important;

  &__text {
    color: #DC5247 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.productStatistic__tables {
  background: #FCF8FF;
}

.product-statistic-table_total_data__item {
  color: #160027 !important;
}
</style>
