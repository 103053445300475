<script setup>
import LandingTag from '@/components/landing/LandingTag.vue';

const tags = ['Продажи', 'Конверсия', 'Переходы', 'Пользователи'];
</script>

<template>
  <div class="landing_tags">
    <div class="landing_tags__container">
      <div>
        <h5 class="landing_tags__title">ПОПУЛЯРНЫЕ</h5>
        <h5 class="landing_tags__title landing_tags__title-bottom">
          ТЕГИ и категории
        </h5>
      </div>

      <div class="landing_tags__items">
        <LandingTag v-for="tag in tags" :key="tag" :tag="tag" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.landing_tags {
  margin-top: 40px;
  @media screen and (min-width: 570px) {
    margin-top: 150px;
    display: flex;
    justify-content: end;
  }

  &__container {
    @media screen and (min-width: 570px) {
      display: flex;
      justify-content: space-between;
      gap: 150px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  &__items {
    padding-right: 5rem;
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 20px;
    @media screen and (min-width: 570px) {
      justify-content: end;
      margin-top: 0px;
    }
    gap: 10px;
  }
}
</style>
